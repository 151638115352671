import { errorMsg } from '../constants/messages';

const applicationListFormData = [
    {
        title: 'Application Details',
        name: 'id',
        label: 'Application Id',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Application Id', allowAddRecordClick: true },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'companyName',
        label: 'Company Name',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Company Name' },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'planName',
        label: 'Plan Name',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Plan Name' },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'authorizedSignature',
        label: 'Authorized Signer',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Authorized Signer' },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'applicantName',
        label: 'Applicant Name',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Applicant Name' },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'submittedByName',
        label: 'Submitted By',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Submitted By' },
        isTableHeader: false,
        isAccordian: false,
    },
    {
        name: 'submittedDate',
        label: 'Submitted Date',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Submitted Date', enableSort: true, tableKey: 'submittedDate' },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'adminStatus',
        label: 'adminStatus',
        fieldType: {
            name: 'select',
            options: [
                { name: 'Active', value: 'active' },
                { name: 'Inactive', value: 'inactive' },
                { name: 'Pending', value: 'pending' },
                { name: 'Rejected', value: 'rejected' },
            ],
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: {
            title: 'Status',
            fiterIcon: true,
            filterOptions: [
                'DRAFT',
                'Agreement Shared',
                'Invoice shared',
                'Waiting for OFAC',
                'OFAC completed',
                'Application approved',
                'Application denied',
                'In review',
            ],
            filterKey: 'adminStatus',
        },
        isTableHeader: true,
        isAccordian: true,
    },
];

export { applicationListFormData };
