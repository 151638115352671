import './style.scss';

interface ModalComponentProps {
    isOpen: boolean;
    onClose?: () => void;
    title?: string;
    children: React.ReactNode;
    width?: string;
    height?: string;
    customClass?: string;
    centered?: boolean;
    isCrossIcon?: boolean;
}

const ModalComponent: React.FC<ModalComponentProps> = ({
    isOpen,
    onClose,
    title,
    children,
    width = '500px',
    height = 'auto',
    customClass = '',
    centered = false,
    isCrossIcon,
}) => {
    if (!isOpen) return null;

    return (
        <div className='modal-overlay' onClick={onClose}>
            <div
                className={`modal-container ${customClass} ${centered ? 'centered' : ''}`}
                style={{ width, height }}
                onClick={(e) => e.stopPropagation()}>
                {isCrossIcon && (
                    <button className='modal-close-button' onClick={onClose}>
                        &times;
                    </button>
                )}

                {title && (
                    <div className='modal-header'>
                        <div className='modal-title'>{title}</div>
                        <button className='modal-close' onClick={onClose}>
                            &times;
                        </button>
                    </div>
                )}
                <div className='modal-body'>{children}</div>
            </div>
        </div>
    );
};

export default ModalComponent;
