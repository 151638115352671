/* eslint-disable no-console */
import { useState, useEffect } from 'react';
import { Dropdown, Button, Checkbox, Radio } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';

import FilterIcon from '../../assets/svg/filterIcon.svg';
import { CLAIM_MODULE } from '../../constants/EntityName';
import './styles.scss';

const StatusDropdown = ({
    filterOption,
    filterKey,
    entityName,
}: {
    filterOption: string[];
    filterKey: string;
    entityName: string;
}) => {
    const [selectedStatuses, setSelectedStatuses] = useState<string[]>([]);
    const [selectedStatus, setSelectedStatus] = useState<string | null>(null);
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        const params = new URLSearchParams(location.search);

        if (entityName === CLAIM_MODULE) {
            const selected = params.get(filterKey);
            setSelectedStatus(selected);
        } else {
            const selected = params.getAll(filterKey);
            setSelectedStatuses(selected);
        }
    }, [location.search, filterKey, entityName]);

    const handleCheckboxChange = (checkedValues: any) => {
        setSelectedStatuses(checkedValues);
    };

    const handleRadioChange = (e: any) => {
        setSelectedStatus(e.target.value);
    };

    const handleReset = () => {
        if (entityName === CLAIM_MODULE) {
            setSelectedStatus(null);
        } else {
            setSelectedStatuses([]);
        }

        const params = new URLSearchParams(location.search);
        params.delete(filterKey);

        history.push({
            pathname: location.pathname,
            search: `?${params.toString()}`,
        });

        setDropdownVisible(false);
    };

    const handleOk = () => {
        const params = new URLSearchParams(location.search);
        params.delete(filterKey);

        if (entityName === CLAIM_MODULE && selectedStatus) {
            params.append(filterKey, selectedStatus);
        } else {
            selectedStatuses.forEach((status) => {
                params.append(filterKey, status);
            });
        }

        history.push({
            pathname: location.pathname,
            search: `?${params.toString()}`,
        });

        setDropdownVisible(false);
    };

    const menu =
        entityName === CLAIM_MODULE ? (
            <div className='radio-dropdown'>
                <Radio.Group
                    options={filterOption}
                    value={selectedStatus}
                    onChange={handleRadioChange}
                    className='status-radio-group'
                />
                <div className='dropdown-footer'>
                    <Button onClick={handleReset} className='reset-button'>
                        Reset
                    </Button>
                    <Button type='primary' onClick={handleOk}>
                        OK
                    </Button>
                </div>
            </div>
        ) : (
            <div className='status-dropdown-menu absolute-dropdown'>
                <Checkbox.Group
                    options={filterOption}
                    value={selectedStatuses}
                    onChange={handleCheckboxChange}
                    className='status-checkbox-group'
                />
                <div className='dropdown-footer'>
                    <Button onClick={handleReset} className='reset-button'>
                        Reset
                    </Button>
                    <Button type='primary' onClick={handleOk}>
                        OK
                    </Button>
                </div>
            </div>
        );

    return (
        <Dropdown
            overlay={menu}
            trigger={['click']}
            placement='bottomLeft'
            visible={dropdownVisible}
            onVisibleChange={(visible) => setDropdownVisible(visible)}>
            <img style={{ cursor: 'pointer' }} src={FilterIcon} alt='Filter Icon' height={11} width={10} />
        </Dropdown>
    );
};

export default StatusDropdown;
