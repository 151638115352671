/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { Input, Button, Table, Form, message } from 'antd';

import EditIcon from '../../../assets/svg/editAction.svg';
import DeleteIcon from '../../../assets/svg/deleteAction.svg';

import ActionModals from '../../../components/ActionModals';
import DeleteModalImg from '../../../assets/svg/deleteModalImg.svg';
import callApi from '../../../Api/config';
import { toastError, toastSuccess } from '../../../service/Toast';
import { PREMIUM_SPLIT_RECIPIENT, PREMIUM_SPLIT_RECIPIENTS } from '../../../constants/EntityName';
import { REGEX_BANK_ACCOUNT, REGEX_ONLY_NUMERIC, REGEX_ROUTING_NUMBER } from '../../../utils/Regexp/Regex';
import './style.scss';
import ModalComponent from '../../../components/ModalComponent';

interface Recipient {
    recipientName: string;
    accountNumber: string;
    routingNumber: string;
}

const RecipientSetup: React.FC = () => {
    const [recipients, setRecipients] = useState<Recipient[]>([]);
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState<any>({ visible: false, id: '' });
    const [isEditModalVisible, setIsEditModalVisible] = useState<any>({ visible: false, id: '' });
    const [recipientData, setRecipientData] = useState<any>([]);
    const [form] = Form.useForm();
    const [editForm] = Form.useForm();
    const [totalCount, setTotalCount] = useState<number>();

    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
    });

    let previousPaginationCount = 1;

    const setPaginationCount = (currentPaginationCount: number) => {
        if (currentPaginationCount === previousPaginationCount) {
            return;
        }
        previousPaginationCount = currentPaginationCount;
        setTotalCount(currentPaginationCount);
    };

    const fetchRecipientDetails = async (paginationEvent = pagination) => {
        const queryParams: any = {};
        if (paginationEvent) {
            const { current, pageSize } = paginationEvent;
            queryParams.page = current;
            queryParams.limit = pageSize;
        }
        try {
            const response = await callApi(PREMIUM_SPLIT_RECIPIENTS, 'GET', null, queryParams);

            const totalCount = response?.data?.data?.total;

            setPaginationCount(totalCount);

            if (response?.status) {
                setRecipientData(response?.data?.data?.results);
            } else {
                toastError('Error fetching Recipient details!');
            }
        } catch (error) {
            console.error('Error during API hit:', error);
        }
    };

    useEffect(() => {
        fetchRecipientDetails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const formattedRecipients = recipientData?.map((item: any) => ({
            key: item.id,
            recipientName: item.recipientName,
            accountNumber: item.accountNumber,
            routingNumber: item.routingNumber,
        }));
        setRecipients(formattedRecipients || []);
    }, [recipientData]);

    const onAddRecipient = async () => {
        try {
            const values = await form.validateFields();
            const newRecipient: Recipient = {
                recipientName: values.name,
                accountNumber: values.accountNumber,
                routingNumber: values.routingNumber,
            };

            const response = await callApi(PREMIUM_SPLIT_RECIPIENT, 'POST', newRecipient);

            if (response?.status) {
                toastSuccess('Recipient added successfully');
                setRecipients([...recipients, newRecipient]);
                form.resetFields();
                fetchRecipientDetails();
            } else {
                toastError(response?.data?.error);
            }
        } catch (error) {
            console.error('Error adding recipient:', error);
        }
    };

    const columns = [
        {
            title: 'Recipient Name',
            dataIndex: 'recipientName',
            key: 'recipientName',
            ellipsis: false,
            width: 400,
            render: (text: string) => <p style={{ wordBreak: 'break-word', whiteSpace: 'normal' }}>{text}</p>,
        },
        {
            title: 'Bank Routing Number',
            dataIndex: 'routingNumber',
            key: 'routingNumber',
            render: (text: string) => `******${text.slice(-6)}`,
        },
        {
            title: 'Bank Account Number',
            dataIndex: 'accountNumber',
            key: 'accountNumber',
            render: (text: string) => `******${text.slice(-5)}`,
        },
        {
            title: 'Action',
            key: 'action',
            render: (record: any) => (
                <span>
                    <img
                        src={EditIcon}
                        alt='Edit Icon'
                        width={28}
                        height={28}
                        style={{ marginLeft: '10px', marginRight: '5px', cursor: 'pointer' }}
                        onClick={() => {
                            setIsEditModalVisible({ visible: true, id: record.key });
                            editForm.setFieldsValue({
                                recipientName: record.recipientName,
                                bankRoutingNumber: record.routingNumber,
                                bankAccountNumber: record.accountNumber,
                            });
                        }}
                    />
                </span>
            ),
        },
    ];

    const handleRecipientDeleteClick = async () => {
        try {
            const response = await callApi(`${PREMIUM_SPLIT_RECIPIENT}/${isDeleteModalVisible?.id}`, 'DELETE');

            if (response?.status) {
                setIsDeleteModalVisible({ visible: false });
                toastSuccess('Recipient Deleted Successfully');
                fetchRecipientDetails();
            } else {
                toastError('Error deleting recipient');
            }
        } catch (error) {
            console.error('Error during API hit:', error);
        }
    };

    const updateRecipientDetail = async (id: string) => {
        try {
            const values = await editForm.validateFields();

            if (!values.recipientName || !values.bankAccountNumber || !values.bankRoutingNumber) {
                toastError('All fields are required. Please fill in the missing details.');
                return;
            }

            const payload: Recipient = {
                recipientName: values.recipientName,
                accountNumber: values.bankAccountNumber,
                routingNumber: values.bankRoutingNumber,
            };

            const response = await callApi(`${PREMIUM_SPLIT_RECIPIENT}/${id}`, 'PATCH', payload);

            if (response?.status) {
                fetchRecipientDetails();
                toastSuccess('Recipient detail updated successfully.');
                setIsEditModalVisible({ visible: false });
                fetchRecipientDetails();
            } else {
                toastError(response?.data?.error);
            }
        } catch (error) {
            console.error('Validation or API error:', error);
            toastError('An unexpected error occurred. Please check the form fields.');
        }
    };

    const onPageChange = async (event: any) => {
        setPagination(event);
        fetchRecipientDetails(event);
    };

    return (
        <div>
            <Form layout='inline' form={form} style={{ paddingBottom: '35px' }}>
                <div className='recipient-form-container'>
                    <div className='recipient-form-fields'>
                        <Form.Item
                            name='name'
                            label='Recipient name*'
                            rules={[{ required: true, message: 'Please enter the recipient name.' }]}>
                            <Input placeholder='Enter recipient name' maxLength={255} />
                        </Form.Item>
                        <Form.Item
                            name='routingNumber'
                            label='Bank routing number*'
                            rules={[
                                { required: true, message: 'Please enter the bank routing number.' },
                                {
                                    pattern: REGEX_ROUTING_NUMBER,
                                    message: 'Bank routing number must be exactly 9 digits long.',
                                },
                            ]}>
                            <Input
                                placeholder='Enter bank routing number'
                                maxLength={9}
                                onKeyPress={(e) => {
                                    if (!REGEX_ONLY_NUMERIC.test(e.key)) {
                                        e.preventDefault();
                                    }
                                }}
                            />
                        </Form.Item>
                        <Form.Item
                            name='accountNumber'
                            label='Bank account number*'
                            rules={[
                                { required: true, message: 'Please enter the bank account number.' },
                                {
                                    pattern: REGEX_BANK_ACCOUNT,
                                    message: 'Bank account number length range is 8 to 12 digits.',
                                },
                            ]}>
                            <Input
                                placeholder='Enter bank account number'
                                onKeyPress={(e) => {
                                    if (!REGEX_ONLY_NUMERIC.test(e.key)) {
                                        e.preventDefault();
                                    }
                                }}
                            />
                        </Form.Item>
                    </div>
                    <div className='recipient-btn'>
                        <Button type='primary' className='add-button' onClick={onAddRecipient}>
                            Add Recipient
                        </Button>
                    </div>
                </div>
            </Form>

            <hr />

            <div className='table-container'>
                <h3 style={{ fontSize: '20px', fontWeight: 700 }}>List of all recipients</h3>
                <Table
                    columns={columns}
                    onChange={onPageChange}
                    dataSource={recipients}
                    rowKey='accountNumber'
                    pagination={{
                        ...pagination,
                        total: totalCount,
                        showSizeChanger: false,
                    }}
                    scroll={{
                        y: 500,
                        x: 'max-content',
                    }}
                />
            </div>

            <ModalComponent
                isOpen={isEditModalVisible.visible}
                onClose={() => setIsEditModalVisible({ visible: false })}
                width='523px'
                height='478px'
                isCrossIcon={false}
                customClass='recipient-edit-modal'>
                <Form form={editForm} layout='vertical' className='recipient-edit-modal1'>
                    <h2 style={{ fontWeight: 'bold' }}>Edit recipients</h2>
                    <Form.Item name='recipientName' label='Recipient name*' required className='form-item'>
                        <Input placeholder='Enter recipient name' />
                    </Form.Item>

                    <Form.Item
                        name='bankRoutingNumber'
                        label='Bank routing number*'
                        required
                        className='form-item'
                        rules={[
                            {
                                pattern: REGEX_ROUTING_NUMBER,
                                message: 'Bank routing number must be exactly 9 digits long!',
                            },
                        ]}>
                        <Input
                            placeholder='Enter bank routing number'
                            maxLength={9}
                            onKeyPress={(e) => {
                                if (!REGEX_ONLY_NUMERIC.test(e.key)) {
                                    e.preventDefault();
                                }
                            }}
                        />
                    </Form.Item>

                    <Form.Item
                        name='bankAccountNumber'
                        label='Bank account number*'
                        required
                        className='form-item'
                        rules={[
                            {
                                pattern: REGEX_BANK_ACCOUNT,
                                message: 'Account number must be between 8 and 12 digits long!',
                            },
                        ]}>
                        <Input
                            placeholder='Enter account number'
                            onKeyPress={(e) => {
                                if (!REGEX_ONLY_NUMERIC.test(e.key)) {
                                    e.preventDefault();
                                }
                            }}
                        />
                    </Form.Item>

                    <div className='edit-footer'>
                        <Button className='cancel-btn' onClick={() => setIsEditModalVisible({ visible: false })}>
                            Cancel
                        </Button>
                        <Button
                            className='save-btn'
                            type='primary'
                            onClick={() => updateRecipientDetail(isEditModalVisible.id)}>
                            Save
                        </Button>
                    </div>
                </Form>
            </ModalComponent>

            <ActionModals
                isVisible={isDeleteModalVisible.visible}
                onClose={() => setIsDeleteModalVisible({ visible: false })}>
                <div className='delete-content'>
                    <div className='delete-icon'>
                        <img
                            src={DeleteModalImg}
                            alt='Delete icon'
                            className='delete-svg-img'
                            height={180}
                            width={229}
                        />
                    </div>
                    <h2 className='delete-title'>
                        Are you sure you want to <br /> delete this recipient?
                    </h2>

                    <div className='delete-footer'>
                        <Button className='cancel-btn' onClick={() => setIsDeleteModalVisible({ visible: false })}>
                            Cancel
                        </Button>
                        <Button className='delete-btn' type='primary' onClick={handleRecipientDeleteClick}>
                            Delete
                        </Button>
                    </div>
                </div>
            </ActionModals>
        </div>
    );
};

export default RecipientSetup;
