// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loading-container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center; }

.expanded-table {
  min-width: 1327px;
  height: 100%;
  margin-left: -50px; }

.application-custom-table .cursor-pointer {
  cursor: pointer; }

.schedule-content .total-amount {
  display: flex;
  justify-content: space-around;
  font-weight: bold;
  margin-top: 30px;
  padding-top: 20px;
  border-top: 1px solid #e7e4e4; }
  .schedule-content .total-amount p {
    margin-right: 90px; }

.schedule-content .schedule-modal-head {
  padding-left: 30px;
  padding-top: 20px; }
  .schedule-content .schedule-modal-head h2 {
    margin-bottom: 0px; }

.clickable-link {
  text-decoration: underline;
  cursor: pointer; }

.schedule-modal-table {
  height: 300px;
  width: 100%;
  padding: 10px 20px; }
  .schedule-modal-table .ant-table-content {
    width: 100%;
    height: 20%; }
`, "",{"version":3,"sources":["webpack://./src/pages/PremiumSplit/PremiumSplitProcess/SplitProcessExpandTable/style.scss"],"names":[],"mappings":"AAAA;EACI,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,mBAAmB,EAAA;;AAGvB;EACI,iBAAiB;EACjB,YAAY;EACZ,kBAAkB,EAAA;;AAGtB;EAEQ,eAAe,EAAA;;AAIvB;EAEQ,aAAa;EACb,6BAA6B;EAC7B,iBAAiB;EACjB,gBAAgB;EAChB,iBAAiB;EACjB,6BAAwC,EAAA;EAPhD;IAUY,kBAAkB,EAAA;;AAV9B;EAeQ,kBAAkB;EAClB,iBAAiB,EAAA;EAhBzB;IAmBY,kBAAkB,EAAA;;AAK9B;EACI,0BAA0B;EAC1B,eAAe,EAAA;;AAGnB;EACI,aAAa;EACb,WAAW;EACX,kBAAkB,EAAA;EAHtB;IAMQ,WAAW;IACX,WAAW,EAAA","sourcesContent":[".loading-container {\n    height: 100%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n.expanded-table {\n    min-width: 1327px;\n    height: 100%;\n    margin-left: -50px;\n}\n\n.application-custom-table {\n    .cursor-pointer {\n        cursor: pointer;\n    }\n}\n\n.schedule-content {\n    .total-amount {\n        display: flex;\n        justify-content: space-around;\n        font-weight: bold;\n        margin-top: 30px;\n        padding-top: 20px;\n        border-top: 1px solid rgb(231, 228, 228);\n\n        p{\n            margin-right: 90px;\n        }\n    }\n\n    .schedule-modal-head{\n        padding-left: 30px;\n        padding-top: 20px;\n\n        h2{\n            margin-bottom: 0px;\n        }\n    }\n}\n\n.clickable-link {\n    text-decoration: underline;\n    cursor: pointer;\n}\n\n.schedule-modal-table{\n    height: 300px;\n    width: 100%;\n    padding: 10px 20px;\n\n    .ant-table-content{\n        width: 100%;\n        height: 20%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
