import { Modal } from 'antd';

const ActionModals = (props: any) => (
    <Modal
        visible={props.isVisible ? true : false}
        onCancel={props.onClose}
        footer={null}
        closable={props.closable}
        centered
        style={{ top: '16%', right: '30%', position: 'absolute' }}
        className='reusable-action-modal'>
        {props.children}
    </Modal>
);

export default ActionModals;
