/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import React, { useState } from 'react';
import { Modal, Button, Input } from 'antd';

import { useHistory } from 'react-router-dom';

import callApi from '../../Api/config';
import { CLAIM_APPROVE, CLAIM_DECLINE, CLAIM_PAID } from '../../constants/EntityName';
import { toastError, toastSuccess } from '../../service/Toast';

import './style.scss';

interface ClaimActionModalProps {
    visible: boolean;
    onCancel: () => void;
    claimActionIconType: string | undefined;
    claimId: string[];
    fetchClaimsById: (() => void) | undefined;
}

const ClaimActionModal: React.FC<ClaimActionModalProps> = ({
    visible,
    onCancel,
    claimActionIconType,
    claimId,
    fetchClaimsById,
}) => {
    const [reason, setReason] = useState<string>('');

    const history = useHistory();

    const handleClaimAction = async (value: string) => {
        if (!reason.trim()) {
            toastError('Please provide a reason.');
            return;
        }

        let url;

        if (value === 'approve') {
            url = CLAIM_APPROVE;
        } else if (value === 'decline') {
            url = CLAIM_DECLINE;
        } else {
            url = CLAIM_PAID;
        }

        const payload = {
            'claimId': claimId,
            'comment': reason,
        };
        try {
            const response = await callApi(url, 'POST', payload);
            if (response?.status) {
                if (value === 'approve') {
                    toastSuccess('Claim Approved');
                } else if (value === 'decline') {
                    toastError('Claim Declined');
                } else {
                    toastSuccess('Claim Paid');
                }
                onCancel();
                setReason('');

                const params = new URLSearchParams(location.search);
                params.delete('claimStatus');

                history.push({
                    pathname: location.pathname,
                    search: `?${params.toString()}`,
                });
                fetchClaimsById && fetchClaimsById();
            }
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <Modal
            visible={visible}
            title='Provide Comment'
            onCancel={onCancel}
            className='claim-modal'
            footer={[
                claimActionIconType === 'In review' ? (
                    <>
                        <Button key='decline' onClick={() => handleClaimAction('decline')} className='decline-btn'>
                            Reject
                        </Button>
                        <Button
                            key='approve'
                            type='primary'
                            onClick={() => handleClaimAction('approve')}
                            className='approve-btn'>
                            Approve
                        </Button>
                    </>
                ) : (
                    claimActionIconType === 'Approved' && (
                        <Button
                            key='approve'
                            type='primary'
                            onClick={() => handleClaimAction('paid')}
                            className='approve-btn'>
                            Mark as Paid
                        </Button>
                    )
                ),
            ]}>
            <p>
                {claimActionIconType === 'Approved'
                    ? 'Please provide a comment to mark claim as Paid.'
                    : 'Please provide a comment to approve or reject the claim.'}
            </p>
            <Input.TextArea
                maxLength={500}
                rows={4}
                value={reason}
                onChange={(e) => setReason(e.target.value)}
                placeholder='Type here...'
            />
        </Modal>
    );
};

export default ClaimActionModal;
