/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import { useEffect, useState } from 'react';
import { Table } from 'antd';

import { useHistory, useLocation, useParams } from 'react-router-dom';

import { ColumnsType, TableProps } from 'antd/es/table';

import DefaultTableHeader from '../../Entity/AbstractLanding/DefaultTableHeader';
import callApi from '../../../Api/config';
import { CLAIM_MODULE, CLAIM_REQUEST_BY_ID } from '../../../constants/EntityName';
import { toastError } from '../../../service/Toast';

import DeclineCross from '../../../assets/svg/deniedIcon.svg';
import WaitIcon from '../../../assets/svg/waiting.svg';
import StatusDropdown from '../../../components/StatusDropdown';
import InfoIcon from '../../../assets/svg/infoIcon.svg';
import CheckIcon from '../../../assets/svg/checkIcon.svg';

import './style.scss';
import { formatWithCommas } from '../../../utils/functions';
import { AppRoutes } from '../../../constants/AppConstant';

interface ClaimRecord {
    id: string;
    approvalDate: string;
    claimReason: string;
    claimAmount: number;
    comments: string;
    claimStatus: string;
}

interface ApiQueryParams {
    search?: string;
    claimStatus?: string[] | string;
    page?: number;
    limit?: number;
}

type ClaimStatus = 'Draft' | 'In review' | 'Approved' | 'Rejected' | 'Paid';

interface ClaimFilters {
    claimStatus: ClaimStatus[];
}

const ClaimListingPage = () => {
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
    });

    const [claimsDataById, setClaimsDataById] = useState<ClaimRecord[]>([]);
    const [claimActionIconType, setClaimActionIconType] = useState<string>('');
    const [expandedCommentId, setExpandedCommentId] = useState<string | null>(null);
    const [totalCount, setTotalCount] = useState<number>();
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [searchQuery, setSearchQuery] = useState<string>();
    const [isCheckBoxDisable, setIsCheckBoxDisable] = useState<boolean>(false);
    const [isActionStatus, setIsActionStatus] = useState<boolean>(false);
    const [filters, setFilters] = useState<ClaimFilters>({
        claimStatus: [],
    });

    const { id } = useParams<{ id: string }>();
    const location = useLocation();
    const history = useHistory();

    const queryParams = new URLSearchParams(location.search);
    const claimStatus = queryParams.get('claimStatus');

    let previousPaginationCount = 1;

    const toggleComment = (id: string) => {
        setExpandedCommentId(expandedCommentId === id ? null : id);
    };

    const setPaginationCount = (currentPaginationCount: number) => {
        if (currentPaginationCount === previousPaginationCount) {
            return;
        }
        previousPaginationCount = currentPaginationCount;
        setTotalCount(currentPaginationCount);
    };

    const formatDate = (dateString: string): string => {
        const date = new Date(dateString);
        return new Intl.DateTimeFormat('en-US', {
            month: 'long',
            day: '2-digit',
            year: 'numeric',
        }).format(date);
    };

    const fetchClaimsById = async (paginationEvent = pagination) => {
        const queryParams: ApiQueryParams = {};

        if (searchQuery) {
            queryParams['search'] = searchQuery;
        }

        const urlParams = new URLSearchParams(window.location.search);

        const filterParams = urlParams.getAll('claimStatus');
        if (filterParams.length > 0) {
            queryParams['claimStatus'] = filterParams;
        }

        if (paginationEvent) {
            const { current, pageSize } = paginationEvent;
            queryParams.page = current;
            queryParams.limit = pageSize;
        }

        if (filters.claimStatus.length > 0) {
            queryParams.claimStatus = filters.claimStatus.join(',');
        }

        try {
            const response = await callApi(`${CLAIM_REQUEST_BY_ID}/${id}`, 'GET', null, queryParams);

            const totalCount = response?.data?.data?.total;

            setPaginationCount(totalCount);

            setSelectedRowKeys([]);
            if (response?.status) {
                const formattedData = response?.data?.data?.results
                    .filter((item: any) => item?.claimStatus !== 'Draft')
                    .map((item: any) => ({
                        id: item?.id,
                        approvalDate: item?.approvalDate ? formatDate(item?.approvalDate) : '-',
                        claimReason: item?.claimReason,
                        claimAmount: `$ ${item?.claimAmount}`,
                        claimStatus: item?.claimStatus,
                        comments: item?.comments,
                        separationDate: item?.separationDate,
                        outstandingLoanBalance: item?.outstandingLoanBalance,
                        loanPaymentAmountPerPeriod: item?.loanPaymentAmountPerPeriod,
                        payrollFrequency: item?.payrollFrequency,
                        approvedOrRejectedBy: item?.approvedOrRejectedBy?.fullName,
                    }));

                const uniqueStatuses = new Set(formattedData.map((item: any) => item.claimStatus));
                setIsCheckBoxDisable(uniqueStatuses.size === 1);
                setIsActionStatus(uniqueStatuses.size === 1 && uniqueStatuses.has('Approved'));
                setClaimsDataById(formattedData);
            } else {
                toastError('Error fetching claims');
            }
        } catch (error) {
            console.error('Error during API hit:', error);
        }
    };

    useEffect(() => {
        fetchClaimsById();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [claimStatus, searchQuery]);

    const onPageChange: TableProps<ClaimRecord>['onChange'] = async (event: any) => {
        setPagination(event);
        fetchClaimsById(event);
    };

    const filterOptions = ['In review', 'Approved', 'Rejected', 'Paid'];

    const handleClaimIdClick = (record: any) => {
        const currentPath = location?.pathname;
        history.push({
            pathname: `${currentPath}/${record.id}`,
            state: { claimRecord: record },
        });
    };

    const columns: ColumnsType<ClaimRecord> = [
        {
            title: 'Claim ID',
            dataIndex: 'id',
            key: 'id',
            render: (claimId, record) => (
                <span
                    onClick={() => handleClaimIdClick(record)}
                    style={{ textDecoration: 'underline', cursor: 'pointer' }}>
                    {claimId}
                </span>
            ),
        },
        {
            title: 'Approval/Rejection Date',
            dataIndex: 'approvalDate',
            key: 'approvalDate',
        },
        {
            title: 'Reason',
            dataIndex: 'claimReason',
            key: 'claimReason',
        },
        {
            title: 'Claim Amount',
            dataIndex: 'claimAmount',
            key: 'claimAmount',
            render: (item: string) => formatWithCommas(item),
        },
        {
            title: 'Comments',
            dataIndex: 'comments',
            key: 'comments',
            width: 400,
            render: (text: string, record: any) => (
                <span style={{ cursor: 'pointer' }} onClick={() => toggleComment(record.id)}>
                    {expandedCommentId === record.id ? (
                        <>
                            {record.comments}{' '}
                            <span
                                style={{ textDecoration: 'underline', fontWeight: 'bold', color: 'gray' }}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    toggleComment(record.id);
                                }}>
                                show less
                            </span>
                        </>
                    ) : record?.comments ? (
                        record?.comments.length > 15 ? (
                            <span>{record.comments.slice(0, 15) + '...'}</span>
                        ) : (
                            record?.comments
                        )
                    ) : (
                        '-'
                    )}
                </span>
            ),
        },
        {
            title: (
                <span style={{ display: 'flex', justifyContent: 'center' }}>
                    {'Status'}
                    <div style={{ marginLeft: '10px' }}>
                        <StatusDropdown
                            filterOption={filterOptions}
                            filterKey={'claimStatus'}
                            entityName={CLAIM_MODULE}
                        />
                    </div>
                </span>
            ),
            dataIndex: 'claimStatus',
            key: 'claimStatus',
            align: 'center',
            render: (status: string, record: ClaimRecord) => {
                if (status === 'In review') {
                    return (
                        <div
                            className='approve-status'
                            style={{ backgroundColor: 'rgba(255, 214, 0, 0.44)', color: '#000' }}>
                            <img src={WaitIcon} alt='wait Icon' style={{ paddingRight: '8px' }} />
                            <span>In review</span>
                        </div>
                    );
                }

                if (status === 'Approved') {
                    return (
                        <div className='approve-status' style={{ backgroundColor: '#D0FFEC', color: '#009257' }}>
                            <img src={InfoIcon} alt='info icon' style={{ paddingRight: '8px' }} />
                            Approved
                        </div>
                    );
                }

                if (status === 'Rejected') {
                    return (
                        <div className='approve-status' style={{ backgroundColor: '#FFD0D0', color: '#B90707' }}>
                            <img src={DeclineCross} alt='denied cross' style={{ paddingRight: '8px' }} />
                            Rejected
                        </div>
                    );
                }

                if (status === 'Paid') {
                    return (
                        <div className='approve-status' style={{ backgroundColor: '#D0FFEC', color: '#009257' }}>
                            <img src={CheckIcon} alt='denied cross' style={{ paddingRight: '8px' }} />
                            Paid
                        </div>
                    );
                }

                return null;
            },
        },
    ];

    return (
        <div className='claim-requests'>
            <DefaultTableHeader
                headerName={'Claim Request'}
                setSearchQuery={setSearchQuery}
                setPhoneSearchQuery={() => 'test'}
                setCitySearchQuery={() => 'test'}
                setStateSearchQuery={() => 'test'}
                disableAdd={true}
                addName='Claim Request'
                selectedRowKeys={selectedRowKeys}
                fetchClaimsById={fetchClaimsById}
                claimActionIconType={claimActionIconType}
                claimStatus={claimStatus}
                isActionStatus={isActionStatus}
            />
            <Table
                columns={columns}
                dataSource={claimsDataById}
                rowKey='id'
                onChange={onPageChange}
                pagination={{
                    ...pagination,
                    total: totalCount,
                    showSizeChanger: false,
                }}
                rowSelection={{
                    type: 'checkbox',
                    selectedRowKeys,
                    onChange: (keys, record) => {
                        setSelectedRowKeys(keys);
                        setClaimActionIconType(record[0]?.claimStatus);
                    },
                    getCheckboxProps: () => ({
                        disabled: !isCheckBoxDisable,
                    }),
                }}
            />
        </div>
    );
};

export default ClaimListingPage;
