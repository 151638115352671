/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import React, { useState } from 'react';
import { Modal, Upload, Input, Button, message } from 'antd';
import { RcFile } from 'antd/lib/upload';

import { DeleteOutlined } from '@ant-design/icons';

import UploadModalIcon from '../../assets/svg/uploadModalIcon.svg';

import './style.scss';

interface FileUploadModalProps {
    isVisible: boolean | undefined;
    onClose: () => void;
    onUpload: (file: RcFile, comment: string) => void;
    applicationId?: string;
}

const PaymentProofUpload: React.FC<FileUploadModalProps> = ({ isVisible, onClose, onUpload, applicationId }) => {
    const [fileList, setFileList] = useState<RcFile[]>([]);
    const [comment, setComment] = useState('');
    const [isUploading, setIsUploading] = useState(false);

    const handleUpload = async (): Promise<void> => {
        if (!comment.trim()) {
            message.warning('Please add a comment');
            return;
        }

        setIsUploading(true);

        try {
            const isSuccess: any = await onUpload(fileList[0], comment);
            if (isSuccess) {
                message.success('Payment Confirmed successfully.');
                setFileList([]);
                setComment('');
            }
        } catch (error) {
            console.error('Error uploading file:', error);
            message.error('An error occurred during payment confirmation');
        } finally {
            setIsUploading(false);
        }
    };

    const beforeUpload = (file: RcFile) => {
        const allowedTypes = [
            'application/pdf',
            'text/csv',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        ];

        const maxFileSize = 5 * 1024 * 1024;

        if (!allowedTypes.includes(file.type)) {
            message.error('You can only upload PDF, CSV, or XLSX files!');
            return Upload.LIST_IGNORE;
        }

        if (file.size > maxFileSize) {
            message.error('Max file size allowed is 5 MB');
            return Upload.LIST_IGNORE;
        }

        setFileList([file]);
        return false;
    };

    return (
        <Modal
            title='File Upload'
            visible={isVisible}
            onCancel={onClose}
            className='upload-modal'
            footer={[
                <Button className='cancel-btn' key='cancel' onClick={onClose}>
                    Cancel
                </Button>,
                <Button
                    className='continue-btn'
                    key='upload'
                    type='primary'
                    onClick={handleUpload}
                    loading={isUploading}>
                    Mark as paid
                </Button>,
            ]}>
            <div className='file-upload-container'>
                <Upload.Dragger
                    beforeUpload={beforeUpload}
                    fileList={fileList}
                    accept='.pdf,.csv,.xlsx'
                    onRemove={() => setFileList([])}
                    itemRender={(originNode, file, fileList, actions) => (
                        <div className='uploaded-file'>
                            <span>{file.name}</span>
                            <DeleteOutlined onClick={() => actions.remove()} className='delete-icon' />
                        </div>
                    )}>
                    <p className='ant-upload-drag-icon'>
                        <img src={UploadModalIcon} alt='Upload Modal Icon' width={48} height={48} />
                    </p>
                    <p className='ant-upload-text'>
                        Attach a file as proof of payment (e.g., a scanned <br /> check or payment receipt).
                    </p>
                </Upload.Dragger>
                <div className='hint-container'>
                    <p className='ant-upload-hint'>Formats accepted are PNG, JPEG, PDF (Max file : 5MB)</p>
                </div>
                <p className='comment-text'>Add comments*</p>
                <Input.TextArea
                    maxLength={500}
                    required
                    placeholder='Provide additional details about the payment. This will be recorded for documentation purposes.'
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    className='comment-input'
                />
            </div>
        </Modal>
    );
};

export default PaymentProofUpload;
