// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.recipient-setup-container {
  padding: 20px;
  min-height: 80vh; }
  .recipient-setup-container .ant-tabs-tab-active .ant-tabs-ink-bar-animated {
    background-color: #000;
    height: 5px !important;
    width: 200px !important;
    left: 381px !important; }
  .recipient-setup-container .ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #000 !important;
    font-size: 18px;
    font-weight: 700; }
  .recipient-setup-container .ant-tabs-tab-btn {
    color: #000 !important;
    font-size: 18px;
    font-weight: 500; }
  .recipient-setup-container .header {
    display: flex;
    gap: 15px;
    font-weight: bold;
    padding-bottom: 15px;
    border-bottom: 1px solid #e0e0e0; }
  .recipient-setup-container .form-container {
    margin: 20px 0; }
    .recipient-setup-container .form-container .ant-form-item {
      margin-right: 20px; }
    .recipient-setup-container .form-container .add-button {
      margin-top: 8px; }
  .recipient-setup-container .table-container {
    margin-top: 20px; }
    .recipient-setup-container .table-container .table-header {
      font-weight: bold; }

.ant-tabs-ink-bar {
  padding: 2px;
  background-color: #000 !important; }
`, "",{"version":3,"sources":["webpack://./src/pages/PremiumSplit/style.scss"],"names":[],"mappings":"AAAA;EACI,aAAa;EACb,gBAAgB,EAAA;EAFpB;IAKM,sBAAsB;IACtB,sBAAsB;IACtB,uBAAuB;IACvB,sBAAsB,EAAA;EAR5B;IAYM,sBAAsB;IACtB,eAAe;IACf,gBAAgB,EAAA;EAdtB;IAkBM,sBAAsB;IACtB,eAAe;IACf,gBAAgB,EAAA;EApBtB;IAwBM,aAAa;IACb,SAAS;IACT,iBAAiB;IACjB,oBAAoB;IACpB,gCAAgC,EAAA;EA5BtC;IAgCM,cAAc,EAAA;IAhCpB;MAmCQ,kBAAkB,EAAA;IAnC1B;MAuCQ,eAAe,EAAA;EAvCvB;IA4CM,gBAAgB,EAAA;IA5CtB;MA+CQ,iBAAiB,EAAA;;AAKvB;EACE,YAAY;EACZ,iCAAiC,EAAA","sourcesContent":[".recipient-setup-container {\n    padding: 20px;\n    min-height: 80vh;\n\n    .ant-tabs-tab-active .ant-tabs-ink-bar-animated{\n      background-color: #000;\n      height: 5px !important;\n      width: 200px !important;\n      left: 381px !important;\n    }\n\n    .ant-tabs-tab-active .ant-tabs-tab-btn{\n      color: #000 !important;\n      font-size: 18px;\n      font-weight: 700;\n    }\n\n    .ant-tabs-tab-btn{\n      color: #000 !important;\n      font-size: 18px;\n      font-weight: 500;\n    }\n  \n    .header {\n      display: flex;\n      gap: 15px;\n      font-weight: bold;\n      padding-bottom: 15px;\n      border-bottom: 1px solid #e0e0e0;\n    }\n  \n    .form-container {\n      margin: 20px 0;\n  \n      .ant-form-item {\n        margin-right: 20px;\n      }\n  \n      .add-button {\n        margin-top: 8px;\n      }\n    }\n  \n    .table-container {\n      margin-top: 20px;\n  \n      .table-header {\n        font-weight: bold;\n      }\n    }\n  }\n  \n  .ant-tabs-ink-bar{\n    padding: 2px;\n    background-color: #000 !important;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
