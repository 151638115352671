// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.absolute-dropdown, .radio-dropdown {
  background-color: white;
  border: 1px solid #d9d9d9;
  padding: 8px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  width: max-content; }
  .absolute-dropdown .status-checkbox-group, .radio-dropdown .status-checkbox-group {
    display: flex;
    flex-direction: column; }
    .absolute-dropdown .status-checkbox-group .ant-checkbox-wrapper, .radio-dropdown .status-checkbox-group .ant-checkbox-wrapper {
      padding: 4px 0;
      padding-left: 10px; }
      .absolute-dropdown .status-checkbox-group .ant-checkbox-wrapper:hover, .radio-dropdown .status-checkbox-group .ant-checkbox-wrapper:hover {
        background-color: #4D5153;
        color: #fff;
        border-radius: 2px; }
  .absolute-dropdown .dropdown-footer, .radio-dropdown .dropdown-footer {
    display: flex;
    justify-content: space-between;
    padding-top: 8px;
    border-top: 1px solid #f0f0f0; }
    .absolute-dropdown .dropdown-footer .ant-btn-primary, .radio-dropdown .dropdown-footer .ant-btn-primary {
      background-color: #617889;
      border-color: #617889; }
    .absolute-dropdown .dropdown-footer .reset-button, .radio-dropdown .dropdown-footer .reset-button {
      background-color: transparent;
      border: none;
      color: #5F5F5F;
      padding: 0; }
      .absolute-dropdown .dropdown-footer .reset-button:hover, .radio-dropdown .dropdown-footer .reset-button:hover {
        text-decoration: underline; }

.filter-button {
  display: flex;
  align-items: center;
  gap: 8px; }

.ant-radio-group {
  display: flex !important;
  flex-direction: column !important; }
`, "",{"version":3,"sources":["webpack://./src/components/StatusDropdown/styles.scss"],"names":[],"mappings":"AAAE;EACE,uBAAuB;EACvB,yBAAyB;EACzB,YAAY;EACZ,kBAAkB;EAClB,yCAAyC;EACzC,kBAAkB,EAAA;EANpB;IASI,aAAa;IACb,sBAAsB,EAAA;IAV1B;MAaM,cAAc;MACd,kBAAkB,EAAA;MAdxB;QAiBQ,yBAAyB;QACzB,WAAW;QACX,kBAAkB,EAAA;EAnB1B;IAyBI,aAAa;IACb,8BAA8B;IAC9B,gBAAgB;IAChB,6BAA6B,EAAA;IA5BjC;MA+BM,yBAAyB;MACzB,qBAAqB,EAAA;IAhC3B;MAoCM,6BAA6B;MAC7B,YAAY;MACZ,cAAc;MACd,UAAU,EAAA;MAvChB;QA0CQ,0BAA0B,EAAA;;AAMlC;EACE,aAAa;EACb,mBAAmB;EACnB,QAAQ,EAAA;;AAGX;EACC,wBAAwB;EACxB,iCAAiC,EAAA","sourcesContent":["  .absolute-dropdown, .radio-dropdown {\n    background-color: white;\n    border: 1px solid #d9d9d9;\n    padding: 8px;\n    border-radius: 4px;\n    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);\n    width: max-content;\n  \n    .status-checkbox-group {\n      display: flex;\n      flex-direction: column;\n  \n      .ant-checkbox-wrapper {\n        padding: 4px 0;\n        padding-left: 10px;\n\n        &:hover {\n          background-color: #4D5153;\n          color: #fff;\n          border-radius: 2px;\n        }\n      }\n    }\n  \n    .dropdown-footer {\n      display: flex;\n      justify-content: space-between;\n      padding-top: 8px;\n      border-top: 1px solid #f0f0f0;\n\n      .ant-btn-primary{\n        background-color: #617889;\n        border-color: #617889;\n      }\n  \n      .reset-button {\n        background-color: transparent;\n        border: none;\n        color: #5F5F5F;\n        padding: 0;\n  \n        &:hover {\n          text-decoration: underline;\n        }\n      }\n    }\n  }\n  \n  .filter-button {\n    display: flex;\n    align-items: center;\n    gap: 8px;\n  }\n\n .ant-radio-group{\n  display: flex !important;\n  flex-direction: column !important;\n } \n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
