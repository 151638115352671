// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.claim-detail-container {
  height: 78vh;
  padding-top: 15px;
  padding-left: 20px; }
  .claim-detail-container .claim-id-row {
    padding: 15px 0;
    padding-left: 5px; }
  .claim-detail-container .actionbar {
    padding-left: 5px !important; }
  .claim-detail-container .claim-id-row {
    font-size: 16px; }
  .claim-detail-container .form-row {
    display: flex;
    gap: 30px;
    margin: 10px 100px 10px 5px; }
    .claim-detail-container .form-row .ant-form-item {
      flex: 1 1; }
      .claim-detail-container .form-row .ant-form-item .ant-input {
        width: 100%; }
    .claim-detail-container .form-row:last-child .ant-form-item {
      max-width: calc((100% - 60px) / 3); }
    .claim-detail-container .form-row .ant-form-item-label > label {
      font-size: 16px !important;
      font-weight: 500; }
    .claim-detail-container .form-row .ant-input {
      height: 53px;
      width: 360px;
      border: 1px solid #CACACA;
      border-radius: 8px; }
`, "",{"version":3,"sources":["webpack://./src/pages/ClaimModule/ClaimListingDetail/style.scss"],"names":[],"mappings":"AAAA;EACI,YAAY;EACZ,iBAAiB;EACjB,kBAAkB,EAAA;EAHtB;IAMQ,eAAe;IACf,iBAAiB,EAAA;EAPzB;IAWQ,4BAA4B,EAAA;EAXpC;IAeQ,eAAe,EAAA;EAfvB;IAoBQ,aAAa;IACb,SAAS;IACT,2BAA2B,EAAA;IAtBnC;MAyBU,SAAO,EAAA;MAzBjB;QA4BY,WAAW,EAAA;IA5BvB;MAkCgB,kCAAkC,EAAA;IAlClD;MAuCY,0BAA0B;MAC1B,gBAAgB,EAAA;IAxC5B;MA4CY,YAAY;MACZ,YAAY;MACZ,yBAAyB;MACzB,kBAAkB,EAAA","sourcesContent":[".claim-detail-container {\n    height: 78vh;\n    padding-top: 15px;\n    padding-left: 20px;\n\n    .claim-id-row{\n        padding: 15px 0;\n        padding-left: 5px;\n    }\n\n    .actionbar{\n        padding-left: 5px !important;\n    }\n\n    .claim-id-row{\n        font-size: 16px;\n\n    }\n\n    .form-row {\n        display: flex;\n        gap: 30px;\n        margin: 10px 100px 10px 5px;\n    \n        .ant-form-item {\n          flex: 1;\n\n          .ant-input {\n            width: 100%;\n            }\n        }\n\n        &:last-child {\n            .ant-form-item {\n                max-width: calc((100% - 60px) / 3);\n            }\n        }\n\n        .ant-form-item-label > label {\n            font-size: 16px !important;\n            font-weight: 500;\n        }\n\n        .ant-input{\n            height: 53px;\n            width: 360px;\n            border: 1px solid #CACACA;\n            border-radius: 8px;\n        }\n      }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
