/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import { Button } from 'antd';
import { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';

import { EyeOutlined, PlusOutlined } from '@ant-design/icons';

import { useSelector } from 'react-redux';

import Search from '../../../components/Search/Search';
import SearchByCity from '../../../components/Search/SearchByCity';
import SearchByPhone from '../../../components/Search/SearchByPhone';
import SearchByState from '../../../components/Search/SearchByState';

import FileUploadModal from '../../../components/FileUploadModal';
import callApi, { uploadImageApiCall } from '../../../Api/config';
import ActionRequiredModal from '../../../components/ActionRequiredModal';
import FilterModal from '../../../components/FilterModal';
import UploadIcon from '../../../assets/svg/ofacUpload.svg';
import { AppRoutes } from '../../../constants/AppConstant';
import PaymentProofUpload from '../../../components/PaymentProofUpload';
import MoneyIcon from '../../../assets/svg/payment.svg';
import ActionModals from '../../../components/ActionModals';
import UpdateImg from '../../../assets/svg/checkSuccess.svg';
import { APPLICATION, PAYMENT_PROOF_STATUS } from '../../../constants/EntityName';
import { toastError, toastSuccess } from '../../../service/Toast';
import ClaimActionModal from '../../../components/ClaimActionModal';

interface IDefaultTableHeader {
    headerName?: string;
    totalCount?: number;
    countText?: string;
    setSearchQuery: (value: any) => void;
    setPhoneSearchQuery: (value: any) => void;
    setCitySearchQuery: (value: any) => void;
    setStateSearchQuery: (value: any) => void;
    onAdd?: () => void;
    addName?: string;
    disableAdd?: boolean;
    disableSearch?: boolean;
    isShowUploadButton?: boolean;
    isCreateMode?: boolean;
    applicationId?: string;
    fetchDocumentData?: () => void;
    handleFliterSubmit?: (data: string) => void;
    applicationStatus?: string;
    fetchApplicationData?: () => void;
    applicationDocData?: any;
    selectedRowKeys?: any;
    fetchClaimsById?: (() => void) | undefined;
    claimActionIconType?: string;
    claimStatus?: string | null;
    isActionStatus?: boolean;
}

const DefaultTableHeader = (props: IDefaultTableHeader) => {
    const {
        disableSearch,
        isShowUploadButton,
        applicationId,
        fetchDocumentData,
        handleFliterSubmit,
        applicationStatus,
        fetchApplicationData,
        applicationDocData,
        selectedRowKeys,
        fetchClaimsById,
        claimActionIconType,
        claimStatus,
        isActionStatus,
    } = props;
    const [garageSearch, setGarageSearch] = useState('');
    const [stepModal, setStepModal] = useState<string | null>(null);

    const [showApproveButton, setShowApproveButton] = useState(false);

    const [isApproveModalVisible, setIsApproveModalVisible] = useState(false);
    const [modalVisible, setModalVisible] = useState<boolean>(false);

    const [statusModal, isStatusModal] = useState<boolean>(false);

    const record = useSelector((state: any) => state?.globalReducer?.record);

    const isCreateMode = Object.keys(record).length > 0;

    const history = useHistory();

    const handleApprove = (reason: string) => {
        setIsApproveModalVisible(false);
    };

    const handleUpload = async (file: File, comment: string): Promise<boolean> => {
        const formData: any = new FormData();
        formData.append('file', file);
        formData.append('comment', comment);

        try {
            const response = await uploadImageApiCall(`documents/${applicationId}`, formData);
            if (response?.isSuccess) {
                fetchDocumentData && fetchDocumentData();
                fetchApplicationData && fetchApplicationData();
                setShowApproveButton(true);
                return true;
            }
            return false;
        } catch (error) {
            console.error('Error in API call:', error);
            if (error instanceof Error) {
                console.error('Error message:', error.message);
            }
            return false;
        }
    };

    const handlePaymentUpload = async (file: File, comment: string): Promise<boolean> => {
        const formData: any = new FormData();
        if (file) {
            formData.append('file', file);
        }
        formData.append('applicationId', applicationId);
        formData.append('comment', comment);

        try {
            const response = await uploadImageApiCall(PAYMENT_PROOF_STATUS, formData);
            if (response?.isSuccess) {
                setStepModal('2');

                return true;
            }
            return false;
        } catch (error) {
            console.error('Error in API call:', error);
            if (error instanceof Error) {
                console.error('Error message:', error.message);
            }
            return false;
        }
    };

    useEffect(() => {
        if (props.addName === 'Garage Commission') {
            setGarageSearch('Garage');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleProceedOfacCheck = () => {
        setStepModal('3');
    };

    const getSearchPlaceholder = (value: string | undefined) => {
        switch (value) {
            case 'Application':
                return `${value?.toLowerCase()} details`;
            case 'User':
                return `by ${value?.toLowerCase()} details`;
            case 'Company':
                return `plan sponsor details`;
            case 'Admin':
                return `list...`;
            case 'Policies':
                return `list...`;
            case 'Claim':
                return 'list...';
            case 'Claim Request':
                return 'list...';
            default:
                return `${value?.toLowerCase()} name`;
        }
    };

    const getPhoneSearchPlaceholder = (value: string | undefined) => {
        if (value === 'Order') {
            return 'Phone No';
        } else {
            return `${value?.toLowerCase()} name`;
        }
    };

    const getCitySearchPlaceholder = (value: string | undefined) => {
        if (value === 'Order') {
            return 'City Name';
        } else {
            return `${value?.toLowerCase()} name`;
        }
    };

    const getStateSearchPlaceholder = (value: string | undefined) => {
        if (value === 'Order') {
            return 'State Name';
        } else {
            return `${value?.toLowerCase()} name`;
        }
    };

    const handleFilter = () => {
        isStatusModal(true);
    };

    let optionData;

    if (props?.headerName === 'Applications List') {
        optionData = [
            { name: 'DRAFT', value: 'DRAFT' },
            { name: 'Agreement Shared', value: 'Agreement Shared' },
            { name: 'Invoice shared', value: 'Invoice shared' },
            { name: 'Waiting for OFAC', value: 'Waiting for OFAC' },
            { name: 'OFAC completed', value: 'OFAC completed' },
            { name: 'Application approved', value: 'Application approved' },
            { name: 'Application denied', value: 'Application denied' },
        ];
    } else {
        optionData = [
            { name: 'Agent', value: 'Agent' },
            { name: 'Advisor', value: 'Advisor' },
            { name: 'Plan Sponsor', value: 'Plan Sponsor' },
        ];
    }

    const handleApplicationRouteClick = () => {
        if (props.headerName === 'Applications') {
            history.push(AppRoutes.Applications);
        } else if (props.headerName === 'Admin Management') {
            history.push(AppRoutes.AdminUsers);
        } else if (props.headerName === 'User Management') {
            history.push(AppRoutes.Users);
        } else if (props.headerName === 'Claim Request') {
            history.push(AppRoutes.ClaimModule);
        }
    };

    const handleNewAddBtn = () => {
        switch (props.headerName) {
            case 'Users Management':
                history.push(AppRoutes.CreateUser);
                break;
            case 'Plan Sponsor Management':
                history.push(AppRoutes.CreatePlanSponsor);
                break;
            case 'Applications ':
                props.onAdd && props.onAdd();
                break;
            case 'Admin':
                history.push(AppRoutes.CreateAdmin);
                break;
            default:
                break;
        }
    };

    const handleApproveDeclineAction = () => {
        fetchDocumentData && fetchDocumentData();
        fetchApplicationData && fetchApplicationData();
    };

    const adminApplicationStatus = [
        'OFAC completed',
        'Application approved',
        'Application denied',
        'DRAFT',
        'Agreement Shared',
        'Invoice shared',
        'In review',
    ];

    const handlePaymentSuccessClose = () => {
        setStepModal(null);
        fetchDocumentData && fetchDocumentData();
        fetchApplicationData && fetchApplicationData();
    };

    const reviewApplicationAction = async (action: string) => {
        try {
            const response = await callApi(`${APPLICATION}/${action}/${applicationId}`, 'POST', { applicationId });

            if (response?.status) {
                toastSuccess(`Application ${action === 'approve' ? 'approved' : 'rejected'}`);
                setStepModal(null);
                fetchDocumentData && fetchDocumentData();
                fetchApplicationData && fetchApplicationData();
            } else {
                toastError('Error ');
            }
        } catch (error) {
            console.error('Error during API hit:', error);
        }
    };

    return (
        <div className='actionbar'>
            <div className='flex align-center'>
                {props.headerName === 'Applications' ? (
                    <div style={{ paddingLeft: '15px', display: 'flex' }}>
                        <p
                            onClick={handleApplicationRouteClick}
                            style={{
                                fontSize: '24px',
                                marginBottom: 0,
                                cursor: 'pointer',
                            }}>
                            {`${props.headerName} > `}
                        </p>
                        <h2 style={{ marginLeft: '4px', fontWeight: 'bold' }}>Details</h2>
                    </div>
                ) : isCreateMode && props.headerName === 'Admin Management' ? (
                    <div style={{ display: 'flex' }}>
                        <h2 style={{ marginLeft: '4px', fontWeight: 'bold' }}>Edit Admin</h2>
                    </div>
                ) : props.headerName === 'Admin Management' ? (
                    <div style={{ display: 'flex' }}>
                        <p
                            onClick={handleApplicationRouteClick}
                            style={{
                                fontSize: '24px',
                                marginBottom: 0,
                                cursor: 'pointer',
                            }}>
                            {`Add Admin >`}
                        </p>
                        <h2 style={{ marginLeft: '4px', fontWeight: 'bold' }}>New Admin</h2>
                    </div>
                ) : props.headerName === 'User Management' ? (
                    <div style={{ display: 'flex' }}>
                        <p
                            onClick={handleApplicationRouteClick}
                            style={{
                                fontSize: '24px',
                                marginBottom: 0,
                                cursor: 'pointer',
                            }}>
                            {`User Management >`}
                        </p>
                        <h2 style={{ marginLeft: '4px', fontWeight: 'bold' }}>New User</h2>
                    </div>
                ) : props.headerName === 'Claim Request' ? (
                    <div style={{ display: 'flex' }}>
                        <p
                            onClick={handleApplicationRouteClick}
                            style={{
                                fontSize: '24px',
                                marginBottom: 0,
                                cursor: 'pointer',
                            }}>
                            {`Claim Requests >`}
                        </p>
                        <h2 style={{ marginLeft: '4px', fontWeight: 'bold' }}>Claim request id</h2>
                    </div>
                ) : props.headerName === 'Claim Listing Detail' ? (
                    <div style={{ display: 'flex' }}>
                        <p
                            style={{
                                fontSize: '24px',
                                marginBottom: 0,
                                cursor: 'pointer',
                            }}>
                            <span onClick={() => history.push(AppRoutes?.ClaimModule)}>{'Claim Requests > '}</span>
                            <span onClick={() => history.goBack()}>{'Claim request id > '}</span>
                        </p>
                        <h2 style={{ marginLeft: '4px', fontWeight: 'bold' }}>Claim deatils</h2>
                    </div>
                ) : (
                    <h2>{props.headerName}</h2>
                )}
            </div>
            <div className='flex align-center'>
                {!disableSearch && (
                    <Search
                        setSearchQuery={props.setSearchQuery}
                        placeholderValue={getSearchPlaceholder(props.addName)}
                        leftIconImg={true}
                    />
                )}

                {props.headerName === 'Order List' && (
                    <SearchByPhone
                        setPhoneSearchQuery={props.setPhoneSearchQuery}
                        placeholderValue={getPhoneSearchPlaceholder(props.addName)}
                        leftIconImg={true}
                    />
                )}
                {props.headerName === 'Order List' && (
                    <SearchByCity
                        setCitySearchQuery={props.setCitySearchQuery}
                        placeholderValue={getCitySearchPlaceholder(props.addName)}
                        leftIconImg={true}
                    />
                )}

                {props.headerName === 'Order List' && (
                    <SearchByState
                        setStateSearchQuery={props.setStateSearchQuery}
                        placeholderValue={getStateSearchPlaceholder(props.addName)}
                        leftIconImg={true}
                    />
                )}
                <div className='addBtn'>
                    {!props.disableAdd ? (
                        <Button className='newBtn' onClick={handleNewAddBtn}>
                            <PlusOutlined />
                            {props.headerName === 'Users Management'
                                ? 'Create/Assign User'
                                : props.headerName === 'Applications'
                                  ? `New ${props.headerName}`
                                  : `New ${props.addName}`}
                        </Button>
                    ) : applicationStatus && !adminApplicationStatus.includes(applicationStatus) ? (
                        <Button className='upload-btn' onClick={() => setStepModal('3')}>
                            <p>
                                <img src={UploadIcon} alt='uploadIcon' width={24} height={24} />
                                Upload OFAC Check
                            </p>
                        </Button>
                    ) : props.headerName === 'Applications' &&
                      applicationStatus === 'OFAC completed' &&
                      !applicationDocData?.some((doc: any) => doc.category === 'Policy') ? (
                        <div style={{ display: 'flex' }}>
                            <Button className='upload-btn' onClick={() => setStepModal('3')}>
                                <p>
                                    <img src={UploadIcon} alt='uploadIcon' width={24} height={24} />
                                    Re-Upload OFAC Check
                                </p>
                            </Button>
                            <Button className='review-btn' onClick={() => setIsApproveModalVisible(true)}>
                                <EyeOutlined />
                                <p>Review Application</p>
                            </Button>
                        </div>
                    ) : props.headerName === 'Applications' && applicationStatus === 'Invoice shared' ? (
                        <Button className='upload-btn' onClick={() => setStepModal('1')}>
                            <p>
                                <img src={MoneyIcon} alt='uploadIcon' width={24} height={24} />
                                Confirm Payment
                            </p>
                        </Button>
                    ) : props.headerName === 'Applications' && applicationStatus === 'In review' ? (
                        <Button className='upload-btn' onClick={() => setStepModal('4')}>
                            <p>Review Applications</p>
                        </Button>
                    ) : (
                        props.headerName === 'Claim Request' && (
                            <Button
                                disabled={
                                    !selectedRowKeys.length ||
                                    claimActionIconType === 'Rejected' ||
                                    claimActionIconType === 'Paid'
                                }
                                className='upload-btn'
                                onClick={() => setStepModal('5')}>
                                <p>{isActionStatus ? 'Mark as Paid' : 'Approve/Reject Claim'}</p>
                            </Button>
                        )
                    )}
                </div>

                {stepModal && (
                    <FileUploadModal
                        isVisible={stepModal === '3'}
                        onClose={() => setStepModal(null)}
                        onUpload={handleUpload}
                        applicationId={applicationId}
                    />
                )}
                {stepModal && (
                    <PaymentProofUpload
                        isVisible={stepModal === '1'}
                        onClose={() => setStepModal(null)}
                        onUpload={handlePaymentUpload}
                        applicationId={applicationId}
                    />
                )}
                <ClaimActionModal
                    claimActionIconType={claimActionIconType}
                    visible={stepModal === '5'}
                    onCancel={() => setStepModal(null)}
                    claimId={selectedRowKeys}
                    fetchClaimsById={fetchClaimsById}
                />
                <ActionModals isVisible={stepModal === '4'} onClose={() => setStepModal(null)} closable={true}>
                    <div className='login-content'>
                        <h2 className='login-title'>Please approve or reject the application.</h2>
                        <div className='login-footer' style={{ paddingTop: '20px' }}>
                            <Button
                                className='approve-btn'
                                type='primary'
                                onClick={() => reviewApplicationAction('approve')}>
                                Approve
                            </Button>
                            <Button
                                className='decline-btn'
                                type='primary'
                                onClick={() => reviewApplicationAction('reject')}>
                                Decline
                            </Button>
                        </div>
                    </div>
                </ActionModals>
                <ActionModals isVisible={stepModal === '2'} onClose={() => setStepModal(null)}>
                    <div className='update-content'>
                        <div className='update-icon'>
                            <img src={UpdateImg} alt='update img' className='update-svg-img' height={119} width={119} />
                        </div>
                        <h2 className='update-title'>The application has been marked as PAID.</h2>
                        <div className='update-footer'>
                            <Button className='cancel-btn' type='primary' onClick={handlePaymentSuccessClose}>
                                Cancel
                            </Button>
                            <Button className='go-btn' type='primary' onClick={handleProceedOfacCheck}>
                                Proceed to OFAC Check
                            </Button>
                        </div>
                    </div>
                </ActionModals>

                {isApproveModalVisible && (
                    <ActionRequiredModal
                        visible={isApproveModalVisible}
                        onApproveDeclineAction={handleApproveDeclineAction}
                        onCancel={() => setIsApproveModalVisible(false)}
                        applicationId={applicationId}
                    />
                )}
                {statusModal && (
                    <FilterModal
                        visible={true}
                        onSubmit={handleFliterSubmit}
                        onCancel={() => isStatusModal(false)}
                        optionData={optionData}
                    />
                )}
                {(props.headerName === 'Option Type' || props.headerName === 'Collection Type') && (
                    <div className='addBtn'>
                        <Button
                            onClick={() => {
                                setModalVisible(true);
                            }}>
                            Change {props.headerName} position
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
};

DefaultTableHeader.defaultProps = {
    addName: '',
};

export default DefaultTableHeader;
