/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { Table, Spin } from 'antd';
import { CheckCircleOutlined, DownOutlined, LoadingOutlined, UpOutlined } from '@ant-design/icons';

import { ColumnsType } from 'antd/es/table';

import callApi from '../../../../Api/config';

import { PREMIUM_PROCESSED_DATA } from '../../../../constants/EntityName';
import SplitProcessExpandTable from '../SplitProcessExpandTable';
import './style.scss';

interface PendingDataType {
    id: string;
    createdAt: string;
    premiumSplitProcessId: string;
    premiumSplitStatus: string;
    nachaFilePath: string;
}

const SplitProcessTable = ({ searchValue, setCheckedRowKeys }: any) => {
    const [isLoading, setIsLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [total, setTotal] = useState(0);
    const [splitProcessData, setSplitProcessData] = useState<PendingDataType[]>([]);
    const [expandedRowKeys, setExpandedRowKeys] = useState<(string | number)[]>([]);

    const handleExpand = (expanded: boolean, record: PendingDataType) => {
        const key = record.premiumSplitProcessId;

        const newExpandedRowKeys = expanded
            ? [...expandedRowKeys, key]
            : expandedRowKeys.filter((item) => item !== key);

        setExpandedRowKeys(newExpandedRowKeys);
    };

    const scheduledColumns: ColumnsType<PendingDataType> = [
        {
            title: 'Premium split Request id ',
            dataIndex: 'premiumSplitProcessId',
            key: 'premiumSplitProcessId',
            render: (text) => text,
        },
        {
            title: 'Process date',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (date) =>
                new Intl.DateTimeFormat('en-US', {
                    month: 'short',
                    day: 'numeric',
                    year: 'numeric',
                }).format(new Date(date)),
        },
    ];

    const fetchSplitProcessPendingData = async () => {
        setIsLoading(true);
        try {
            const response = await callApi(PREMIUM_PROCESSED_DATA, 'GET');
            const results = response?.data?.data?.results;

            setSplitProcessData(results);
        } catch (error) {
            console.error('Error during API hit:', error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchSplitProcessPendingData();
    }, [currentPage, searchValue]);

    const handleTableChange = (pagination: React.SetStateAction<number>) => {
        setCurrentPage(pagination);
        setPageSize(10);
    };

    const expandedRowRender = (record: PendingDataType) => (
        <SplitProcessExpandTable
            premiumSplitProcessId={record?.premiumSplitProcessId}
            setCheckedRowKeys={setCheckedRowKeys}
            fetchSplitProcessPendingData={fetchSplitProcessPendingData}
        />
    );

    if (isLoading) {
        return (
            <div className='loading-container'>
                <Spin indicator={<LoadingOutlined style={{ fontSize: 100 }} spin />} />
            </div>
        );
    }

    return (
        <>
            {splitProcessData?.length > 0 && (
                <Table
                    dataSource={splitProcessData}
                    columns={scheduledColumns}
                    expandable={{
                        expandedRowKeys,
                        expandedRowRender: (record) => expandedRowRender(record),
                        expandIcon: ({ expanded, onExpand, record }) =>
                            expanded ? (
                                <UpOutlined
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        onExpand(record, e);
                                    }}
                                    style={{ fontSize: 12 }}
                                />
                            ) : (
                                <DownOutlined
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        onExpand(record, e);
                                    }}
                                    style={{ fontSize: 12 }}
                                />
                            ),
                        onExpand: handleExpand,
                    }}
                    pagination={{
                        current: currentPage,
                        pageSize: pageSize,
                        total: total,
                        onChange: handleTableChange,
                        showSizeChanger: false,
                    }}
                    rowKey='premiumSplitProcessId'
                    scroll={{
                        y: 500,
                        x: 'max-content',
                    }}
                    onRow={(record) => ({
                        onClick: (event) => {
                            const key = record.premiumSplitProcessId;
                            const isExpanded = expandedRowKeys.includes(key);

                            const newExpandedRowKeys = isExpanded
                                ? expandedRowKeys.filter((item) => item !== key)
                                : [...expandedRowKeys, key];

                            setExpandedRowKeys(newExpandedRowKeys);
                        },
                        style: { cursor: 'pointer' },
                    })}
                />
            )}
        </>
    );
};

export default SplitProcessTable;
