/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import { message, Spin, Table } from 'antd';

import { CheckCircleOutlined, LoadingOutlined } from '@ant-design/icons';

import { ColumnsType } from 'antd/es/table';

import { useHistory } from 'react-router-dom';

import callApi from '../../../../Api/config';
import { APPLICATION, PREMIUM_SPLIT_PROCESS_BY_ID } from '../../../../constants/EntityName';
import ModalComponent from '../../../../components/ModalComponent';
import './style.scss';
import {
    IApplicationDetail,
    IRowDetail,
    IScheduleData,
    ISplitProcessExpandTableProps,
} from '../../../../constants/AppConstant';
import { toastError } from '../../../../service/Toast';
import CheckIcon from '../../../../assets/svg/checkIcon.svg';
import { formatWithCommas } from '../../../../utils/functions';

const SplitProcessExpandTable = ({
    premiumSplitProcessId,
    setCheckedRowKeys,
    fetchSplitProcessPendingData,
}: ISplitProcessExpandTableProps) => {
    const [rowDetails, setRowDetails] = useState<IRowDetail[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isScheduleModalData, setIsScheduleModalData] = useState<IScheduleData[] | null>(null);
    const [expandedRecordsData, setExpandedRecordsData] = useState<IApplicationDetail[]>([]);
    const [premiumSummaryModal, setPremiumSummaryModal] = useState<any>({});
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleText = () => {
        setIsExpanded(!isExpanded);
    };

    const history = useHistory();

    const handleScheduleModalTableData = (id: string) => {
        const modalTableData = expandedRecordsData.find((item) => item.application_id === id);

        if (modalTableData) {
            setPremiumSummaryModal({
                totalAmount: modalTableData?.totalAmount,
                applicationId: modalTableData?.application_id,
                planSponsorName: modalTableData?.planSponsorName,
            });
            setIsScheduleModalData(modalTableData.premiumSchedule.premiumItems);
        }
    };

    const detailColumns = [
        {
            title: 'Application ID',
            dataIndex: 'applicationId',
            key: 'applicationId',
            render: (applicationId: string, record: IRowDetail) => (
                <span onClick={() => history.push(`${APPLICATION}/${record.applicationId}`)} className='clickable-link'>
                    {applicationId}
                </span>
            ),
        },
        {
            title: 'Company Name',
            dataIndex: 'companyName',
            key: 'companyName',
        },
        {
            title: 'Plan Name',
            dataIndex: 'planName',
            key: 'planName',
        },
        {
            title: 'Total Premium Amount',
            dataIndex: 'totalAmount',
            key: 'totalAmount',
        },
        {
            title: 'Premium Split Schedule',
            dataIndex: 'premiumScheduleName',
            key: 'premiumScheduleName',
            render: (premiumScheduleName: string, record: IRowDetail) => (
                <span onClick={() => handleScheduleModalTableData(record.applicationId)} className='clickable-link'>
                    {premiumScheduleName}
                </span>
            ),
        },
        {
            title: 'Comment',
            dataIndex: 'comment',
            key: 'comment',
            width: 200,
            render: (text: string) => (
                <span style={{ wordBreak: 'break-word', whiteSpace: 'normal' }}>
                    {isExpanded ? (
                        <>
                            {text}{' '}
                            {text.length > 15 && (
                                <span
                                    onClick={toggleText}
                                    style={{ color: 'gray', cursor: 'pointer', textDecoration: 'underline' }}>
                                    show less
                                </span>
                            )}
                        </>
                    ) : (
                        <>
                            {text.slice(0, 15)}{' '}
                            {text.length > 15 && (
                                <span
                                    onClick={toggleText}
                                    style={{ color: 'gray', cursor: 'pointer', textDecoration: 'underline' }}>
                                    show more
                                </span>
                            )}
                        </>
                    )}
                </span>
            ),
        },
        {
            title: 'Status',
            dataIndex: 'premiumSplitStatus',
            key: 'premiumSplitStatus',
            render: (status: string) =>
                status === 'Paid' ? (
                    <div className='approve-status' style={{ backgroundColor: '#D0FFEC', color: '#009257' }}>
                        <img src={CheckIcon} alt='approved check' style={{ paddingRight: '8px' }} />
                        Paid
                    </div>
                ) : (
                    <div className='split-process-status'>
                        <CheckCircleOutlined className='status-icon' />
                        {status}
                    </div>
                ),
        },
    ];

    const scheduleModalColumns: ColumnsType<IScheduleData> = [
        {
            title: 'Premium Type',
            dataIndex: 'premiumType',
            key: 'premiumType',
        },
        {
            title: 'Recipient',
            dataIndex: 'recipientName',
            key: 'recipientName',
        },
        {
            title: 'Allocation percentage',
            dataIndex: 'allocationAmount',
            key: 'allocationAmount',
            render: (item) => <span>{item}%</span>,
            width: 200,
        },
        {
            title: 'Allocation amount',
            dataIndex: 'finalAmount',
            key: 'finalAmount',
            width: 200,
            render: (text) => <span>{formatWithCommas(text)}</span>,
        },
    ];

    const formatDataForTable = (data: IApplicationDetail[], premiumSplitProcessId: string): IRowDetail[] =>
        data.map((item) => ({
            applicationId: item?.application_id,
            companyName: item?.companyName,
            planName: item?.planName,
            totalAmount: `$ ${parseFloat(item?.totalAmount).toLocaleString()}`,
            premiumScheduleName: item?.premiumSchedule.premiumScheduleName,
            premiumSplitProcessId: premiumSplitProcessId,
            premiumSplitStatus: item?.premiumSplitStatus,
            comment: item?.comment ? item?.comment : '-',
        }));

    const fetchExpandedTableData = async () => {
        setIsLoading(true);
        try {
            const response = await callApi(`${PREMIUM_SPLIT_PROCESS_BY_ID}/${premiumSplitProcessId}`, 'GET');

            // eslint-disable-next-line camelcase, no-unsafe-optional-chaining
            const { applications, premium_split_process_id } = response?.data?.data;
            if (response?.status) {
                setRowDetails(formatDataForTable(applications, premium_split_process_id));
                setExpandedRecordsData(applications);
            }
        } catch (error) {
            message.error('An error occurred while fetching details.');
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchExpandedTableData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (isLoading) {
        return (
            <div className='loading-container'>
                <Spin indicator={<LoadingOutlined style={{ fontSize: 100 }} spin />} />
            </div>
        );
    }

    const handleCheckedRow = (currentSelectedRowKeys: React.Key[], currentSelectedRows: IRowDetail[]) => {
        setSelectedRowKeys(currentSelectedRowKeys);

        if (setCheckedRowKeys) {
            setCheckedRowKeys((prevState: Record<string, React.Key[]>) => {
                if (currentSelectedRowKeys.length === 0) {
                    const [lastAccordionId] = Object.entries(prevState).find(
                        ([, keys]) => keys.length > 0 && keys[0] === selectedRowKeys[0], // Match previously selected keys
                    ) || [undefined];

                    if (lastAccordionId) {
                        const updatedState = { ...prevState };
                        delete updatedState[lastAccordionId];
                        return updatedState;
                    }
                }

                const currentTableId = currentSelectedRows[0]?.premiumSplitProcessId;

                if (currentTableId) {
                    const obj = {
                        ...prevState,
                        [currentTableId]: currentSelectedRowKeys,
                    };
                    if (Object.keys(obj).length > 1) {
                        toastError('Please select applications from a single table.');
                    }
                    return obj;
                }

                return prevState;
            });
        }
    };

    return (
        <>
            <Table
                dataSource={rowDetails}
                columns={detailColumns}
                style={{ minWidth: '1327px' }}
                className='expanded-table application-custom-table'
                pagination={false}
                rowKey='applicationId'
                rowClassName={() => 'cursor-pointer'}
                rowSelection={{
                    type: 'checkbox',
                    selectedRowKeys,
                    onChange: handleCheckedRow,
                    getCheckboxProps: (record: IRowDetail) => ({
                        disabled: record.premiumSplitStatus === 'Paid',
                    }),
                }}
            />

            <ModalComponent
                isOpen={!!isScheduleModalData}
                onClose={() => setIsScheduleModalData(null)}
                width='800px'
                height='500px'
                isCrossIcon={true}
                customClass='application-review-class'>
                <div className='schedule-content'>
                    <div className='schedule-modal-head'>
                        <div>
                            <p>
                                PLAN SPONSOR NAME -{' '}
                                <span style={{ fontWeight: 'bold' }}>{premiumSummaryModal?.planSponsorName}</span>
                            </p>
                            <p>
                                APPLICATION ID -{' '}
                                <span style={{ fontWeight: 'bold' }}>{premiumSummaryModal?.applicationId}</span>
                            </p>
                        </div>
                    </div>
                    <div className='schedule-modal-table'>
                        <Table
                            columns={scheduleModalColumns}
                            dataSource={isScheduleModalData || []}
                            pagination={false}
                            bordered
                            scroll={{
                                y: 300,
                                x: 'max-content',
                            }}
                        />
                    </div>
                    <div className='total-amount'>
                        <p>Total</p>
                        <p></p>
                        <p>100%</p>
                        <p>${formatWithCommas(premiumSummaryModal?.totalAmount)}</p>
                    </div>
                </div>
            </ModalComponent>
        </>
    );
};

export default SplitProcessExpandTable;
