// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.split-process-status {
  width: max-content;
  padding: 5px 10px;
  background-color: #ffed8f;
  border-radius: 28px; }
  .split-process-status .status-icon {
    color: #000;
    padding-right: 5px; }

.loading-container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center; }
`, "",{"version":3,"sources":["webpack://./src/pages/PremiumSplit/PremiumSplitProcess/SplitProcessTable/style.scss"],"names":[],"mappings":"AAAA;EACI,kBAAkB;EAClB,iBAAiB;EACjB,yBAAyB;EACzB,mBAAmB,EAAA;EAJvB;IAOQ,WAAW;IACX,kBAAkB,EAAA;;AAI1B;EACI,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,mBAAmB,EAAA","sourcesContent":[".split-process-status {\n    width: max-content;\n    padding: 5px 10px;\n    background-color: #ffed8f;\n    border-radius: 28px;\n\n    .status-icon {\n        color: #000;\n        padding-right: 5px;\n    }\n}\n\n.loading-container {\n    height: 100%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
