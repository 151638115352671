export const REGEX_URL = /[-a-zA-Z0-9@:%._\\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)?/gi;
export const REGEX_MOBILE = /^[6-9]{1}[0-9]{9}$/;
export const REGEX_MIN_VALUE = /^[1-9][0-9]*$/;
export const REGEX_LESS_VALUE = /^[0-9][0-9]*$/;
export const REGEX_MAX_VALUE = /^(?:[0-9]|[1-9][0-9]|100)$/;
export const REGEX_PINCODE_VALUE = /^([1-9]{1}[0-9]{5})$/;
export const REGEX_NO_NUMBER = /^([1-9]{1}[0-9]{5})$/;
export const REGEX_PHONE_NUMBER = /^([1-9]{1}[0-9]{9})$/;
export const REGEX_AADHAAR = /^([1-9]{1}[0-9]{11})$/;
export const REGEX_PASSWORD = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#^()])[A-Za-z\d@$!%*?&#^()]{8,}$/;
export const REGEX_RATING = /^(?:[1-5](?:\.\d{1,1})?|4(?:\.(?:[0-4][0-9]|50?)?))$/;
export const REGEX_PASSWORD_LENGTH_LIMIT =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#^()])[A-Za-z\d@$!%*?&#^()]{8,20}$/;
export const REGEX_NAME = /^[A-Za-z\s]+$/;
export const REGEX_PHONE_FORMATTED = /^[0-9]{3}-[0-9]{3}-[0-9]{4}$/;
export const REGEX_BANK_ACCOUNT = /^\d{8,12}$/;
export const REGEX_ROUTING_NUMBER = /^\d{9}$/;
export const REGEX_ONLY_NUMERIC = /^[0-9]$/;
export const REGEX_TWO_PLACE_DECIMAL = /^\d+(\.\d{1,2})?$/;
export const REGEX_DECIMAL_PLACE_MATCH = /^\d+(\.\d{0,2})?$/;
export const REGEX_PHONE_NUMBER_FORMAT = /^(\d{0,1})(\d{3})(\d{3})(\d{4})$/;
export const REGEX_MAX_DIGIT_LIMIT = /^\d{1,2}(\.\d{1,2})?$/;
export const REGEX_MAX_DIGIT_DECIMAL = /^\d{0,2}(\.\d{0,2})?$/;
export const REGEX_PERCENTAGE = /^(([0-9]|[1-9][0-9])?(\.[0-9]{0,2})?|100(\.0{0,2})?)$/;
