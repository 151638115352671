import React from 'react';
import { Tabs } from 'antd';

import RecipientSetup from './RecipientSetup';
import ScheduleSetup from './ScheduleSetup';
import './style.scss';
import PremiumSplitProcess from './PremiumSplitProcess';

const PremiumSplit: React.FC = () => (
    <>
        <div className='recipient-setup-container'>
            <Tabs defaultActiveKey='1'>
                <Tabs.TabPane tab='Recipient Setup' key='1'>
                    <RecipientSetup />
                </Tabs.TabPane>

                <Tabs.TabPane tab='Premium Schedule Setup' key='2'>
                    <ScheduleSetup />
                </Tabs.TabPane>

                <Tabs.TabPane tab='Premium Split Process' key='3'>
                    <PremiumSplitProcess />
                </Tabs.TabPane>
            </Tabs>
        </div>
    </>
);

export default PremiumSplit;
