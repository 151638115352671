// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000; }

.modal-container {
  background: #fff;
  border-radius: 8px;
  padding: 20px;
  position: relative;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); }
  .modal-container.centered {
    align-items: center; }
  .modal-container.custom-style {
    border: 2px solid #ccc; }

.modal-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 1.5rem;
  color: #333;
  cursor: pointer;
  z-index: 1001;
  height: 20px;
  width: 20px; }

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px; }
  .modal-header .modal-title {
    font-size: 1.25rem;
    font-weight: bold; }
  .modal-header .modal-close {
    background: transparent;
    border: none;
    font-size: 1.5rem;
    cursor: pointer; }

.modal-body {
  max-height: 80vh;
  overflow-y: auto; }
`, "",{"version":3,"sources":["webpack://./src/components/ModalComponent/style.scss"],"names":[],"mappings":"AAAA;EACI,eAAe;EACf,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,8BAA8B;EAC9B,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,aAAa,EAAA;;AAGf;EACE,gBAAgB;EAChB,kBAAkB;EAClB,aAAa;EACb,kBAAkB;EAClB,yCAAyC,EAAA;EAL3C;IAOI,mBAAmB,EAAA;EAPvB;IAUI,sBAAsB,EAAA;;AAI1B;EACE,kBAAkB;EAClB,SAAS;EACT,WAAW;EACX,uBAAuB;EACvB,YAAY;EACZ,iBAAiB;EACjB,WAAW;EACX,eAAe;EACf,aAAa;EACb,YAAY;EACZ,WAAW,EAAA;;AAGb;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,mBAAmB,EAAA;EAJrB;IAOI,kBAAkB;IAClB,iBAAiB,EAAA;EARrB;IAYI,uBAAuB;IACvB,YAAY;IACZ,iBAAiB;IACjB,eAAe,EAAA;;AAInB;EACE,gBAAgB;EAChB,gBAAgB,EAAA","sourcesContent":[".modal-overlay {\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background: rgba(0, 0, 0, 0.5);\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    z-index: 1000;\n  }\n  \n  .modal-container {\n    background: #fff;\n    border-radius: 8px;\n    padding: 20px;\n    position: relative;\n    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);\n    &.centered {\n      align-items: center;\n    }\n    &.custom-style {\n      border: 2px solid #ccc;\n    }\n  }\n\n  .modal-close-button {\n    position: absolute;\n    top: 10px;\n    right: 10px;\n    background: transparent;\n    border: none;\n    font-size: 1.5rem;\n    color: #333;\n    cursor: pointer;\n    z-index: 1001;\n    height: 20px;\n    width: 20px;\n}\n  \n  .modal-header {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    margin-bottom: 10px;\n  \n    .modal-title {\n      font-size: 1.25rem;\n      font-weight: bold;\n    }\n  \n    .modal-close {\n      background: transparent;\n      border: none;\n      font-size: 1.5rem;\n      cursor: pointer;\n    }\n  }\n  \n  .modal-body {\n    max-height: 80vh;\n    overflow-y: auto;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
