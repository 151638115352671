// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.claim-requests {
  padding: 20px;
  background-color: #fff;
  min-height: 80vh; }
  .claim-requests .ant-table-cell {
    border-right: 1px solid #f3f3f3;
    text-align: center; }
  .claim-requests .ant-table-row {
    border-bottom: 1px solid #f3f3f3; }
    .claim-requests .ant-table-row .ant-table-cell {
      padding: 0px; }
  .claim-requests .ant-table-thead > tr > th {
    background-color: #f5f5f5;
    font-weight: bold;
    border-right: 1px solid #f3f3f3; }

.ant-popover-arrow {
  display: none !important; }

#ApproveClaim, #DeclineClaim {
  width: 120px;
  height: 50px; }

.approve-status {
  width: max-content;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 28px;
  margin: 10px;
  padding: 0px 15px; }
`, "",{"version":3,"sources":["webpack://./src/pages/ClaimModule/ClaimListingPage/style.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,gBAAgB,EAAA;EAHlB;IAMM,+BAA+B;IAC/B,kBAAkB,EAAA;EAPxB;IAWM,gCAAgC,EAAA;IAXtC;MAcQ,YAAY,EAAA;EAdpB;IAmBM,yBAAyB;IACzB,iBAAiB;IACjB,+BAA+B,EAAA;;AAIrC;EACE,wBAAwB,EAAA;;AAG1B;EACE,YAAY;EACZ,YAAY,EAAA;;AAGd;EACE,kBAAkB;EAClB,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,mBAAmB;EACnB,YAAY;EACZ,iBAAiB,EAAA","sourcesContent":[".claim-requests {\n  padding: 20px;\n  background-color: #fff;\n  min-height: 80vh;\n\n  .ant-table-cell {\n      border-right: 1px solid #f3f3f3; \n      text-align: center; \n  }\n\n  .ant-table-row {\n      border-bottom: 1px solid #f3f3f3; \n\n      .ant-table-cell{\n        padding: 0px;\n      }\n  }\n\n  .ant-table-thead > tr > th {\n      background-color: #f5f5f5; \n      font-weight: bold;\n      border-right: 1px solid #f3f3f3;\n  }\n}\n\n.ant-popover-arrow {\n  display: none !important;\n}\n\n#ApproveClaim, #DeclineClaim{\n  width: 120px;\n  height: 50px;\n}\n\n.approve-status{\n  width: max-content;\n  height: 32px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  border-radius: 28px;\n  margin: 10px;\n  padding: 0px 15px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
