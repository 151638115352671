import React from 'react';
import { Form, Input } from 'antd';
import { useLocation } from 'react-router-dom';

import DefaultTableHeader from '../../Entity/AbstractLanding/DefaultTableHeader';

import { formFields } from '../../../constants/AppConstant';
import './style.scss';

const ClaimListingDetail: React.FC = () => {
    const { state } = useLocation<any>();
    const claimRecord = state?.claimRecord || {};

    const initialValues = {
        claimReason: claimRecord?.claimReason || '-',
        separationDate: claimRecord?.separationDate || '-',
        outstandingLoanBalance: claimRecord?.outstandingLoanBalance || '-',
        payPeriod: claimRecord?.loanPaymentAmountPerPeriod || '-',
        payrollFrequency: claimRecord?.payrollFrequency || '-',
        totalClaimAmount: claimRecord?.claimAmount || '-',
        approvedOrRejectedBy: claimRecord?.approvedOrRejectedBy || '-',
    };

    return (
        <div className='claim-detail-container'>
            <DefaultTableHeader
                headerName='Claim Listing Detail'
                setSearchQuery={() => 'test'}
                setPhoneSearchQuery={() => 'test'}
                setCitySearchQuery={() => 'test'}
                setStateSearchQuery={() => 'test'}
                disableAdd
                disableSearch
            />
            <div className='claim-id-row'>Claim id - {claimRecord?.id}</div>
            <Form layout='vertical' className='claim-detail-form' initialValues={initialValues}>
                {formFields?.map((row, index) => (
                    <div className='form-row' key={index}>
                        {row?.map((field) => (
                            <Form.Item key={field?.name} name={field?.name} label={field?.label}>
                                <Input disabled />
                            </Form.Item>
                        ))}
                    </div>
                ))}
            </Form>
        </div>
    );
};

export default ClaimListingDetail;
