/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { AutoComplete, Button, Form, Input, Table, message } from 'antd';
import { DownOutlined, UpOutlined } from '@ant-design/icons';

import DeleteIcon from '../../../assets/svg/deleteAction.svg';

import { PREMIUM_SPLIT_RECIPIENTS, PREMIUM_SPLIT_SCHEDULE, PREMIUM_TYPE_LIST } from '../../../constants/EntityName';
import callApi from '../../../Api/config';
import { toastError, toastSuccess } from '../../../service/Toast';
import './style.scss';
import { REGEX_MAX_DIGIT_DECIMAL, REGEX_MAX_DIGIT_LIMIT, REGEX_PERCENTAGE } from '../../../utils/Regexp/Regex';

interface PremiumScheduleData {
    premiumType: string;
    recipient: string;
    allocation: string;
}

interface TableItem {
    key: string;
    name: string;
    premiumType?: string;
    recipient?: string;
    allocation?: string;
    children?: TableItem[];
}

const ScheduleSetup: React.FC = () => {
    const [form] = Form.useForm();
    const [scheduleListData, setScheduleListData] = useState<TableItem[]>([]);
    const [isSaveDisabled, setIsSaveDisabled] = useState(true);
    const [allocationValue, setAllocationValue] = useState<number>(0);
    const [premiumTypes, setPremiumTypes] = useState([]);
    const [recipients, setRecipients] = useState([]);
    const [selectedRecipients, setSelectedRecipients] = useState<string[]>([]);

    const [totalCount, setTotalCount] = useState<number>();
    const [isFieldsEnabled, setIsFieldsEnabled] = useState(false);
    const [expandedRowKeys, setExpandedRowKeys] = useState<any>([]);

    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
    });

    let previousPaginationCount = 1;

    const setPaginationCount = (currentPaginationCount: number) => {
        if (currentPaginationCount === previousPaginationCount) {
            return;
        }
        previousPaginationCount = currentPaginationCount;
        setTotalCount(currentPaginationCount);
    };

    const handleValuesChange = (
        _changedValues: any,
        allValues: { scheduleRows: PremiumScheduleData[]; scheduleName: string },
    ) => {
        const { scheduleRows } = _changedValues;
        if (!scheduleRows) {
            return;
        }
        const { allocation } = scheduleRows.filter((item: any) => item)[0];
        const allAllocationsValid = REGEX_PERCENTAGE.test(allocation);

        if (!allAllocationsValid && allocation !== undefined) {
            return;
        }

        const totalAllocation = allValues?.scheduleRows?.reduce((sum, item) => {
            const value = parseFloat(item.allocation) || 0;
            return sum + value;
        }, 0);

        const roundedTotal = parseFloat(totalAllocation.toFixed(2));
        if (roundedTotal > 100) {
            toastError('Allocation Value should not exceed 100%');
        }

        setAllocationValue(roundedTotal);

        form.setFieldValue('total', `${roundedTotal}%`);

        const allFieldsFilled = allValues?.scheduleRows?.every(
            (row) => row.premiumType && row.recipient && row.allocation,
        );

        const isScheduleNameFilled = Boolean(allValues?.scheduleName && allValues?.scheduleName?.trim());

        setIsSaveDisabled(!(allFieldsFilled && roundedTotal === 100 && isScheduleNameFilled));
    };

    const handleAddPremiumType = () => {
        const currentValues = form.getFieldValue('scheduleRows') || [];
        const newRow = { premiumType: undefined, recipient: undefined, allocation: '' };
        form.setFieldsValue({ scheduleRows: [...currentValues, newRow] });
    };

    const handleRecipientChange = (value: string, index: number) => {
        const currentRows = form.getFieldValue('scheduleRows') || [];
        currentRows[index].recipient = value;
        form.setFieldsValue({ scheduleRows: currentRows });

        const newSelected = currentRows.map((row: any) => row.recipient).filter(Boolean);
        setSelectedRecipients(newSelected);
    };

    const fetchPremiumSchedules = async (paginationEvent = pagination) => {
        const queryParams: any = {};
        if (paginationEvent) {
            const { current, pageSize } = paginationEvent;
            queryParams.page = current;
            queryParams.limit = pageSize;
        }
        try {
            const response = await callApi(PREMIUM_SPLIT_SCHEDULE, 'GET', null, queryParams);
            const results = response?.data?.data?.results || [];

            const totalCount = response?.data?.data?.total;

            setPaginationCount(totalCount);

            const transformedData = results?.map((schedule: any) => {
                const [firstChild, ...remainingChildren] = schedule.premiumItems.map((item: any, index: any) => ({
                    key: `${schedule?.id}-${index}`,
                    premiumType: item?.premiumType,
                    recipient: item?.recipientName,
                    allocation: `${item?.allocationAmount}%`,
                }));

                return {
                    key: schedule?.id,
                    name: schedule?.premiumScheduleName,
                    premiumType: firstChild?.premiumType || '',
                    recipient: firstChild?.recipient || '',
                    allocation: firstChild?.allocation || '',
                    children: remainingChildren,
                };
            });

            setScheduleListData(transformedData);
        } catch (error) {
            console.error('Error during API hit:', error);
            message.error('Failed to fetch premium schedules');
        }
    };

    const fetchPremiumTypeData = async () => {
        try {
            const response = await callApi(PREMIUM_TYPE_LIST, 'GET');
            setPremiumTypes(response?.data?.data?.premiumTypes);
        } catch (error) {
            console.error('Error during API hit:', error);
        }
    };

    const fetchRecipientData = async () => {
        try {
            const response = await callApi(PREMIUM_SPLIT_RECIPIENTS, 'GET');
            setRecipients(
                response?.data?.data?.results?.map((item: any) => ({
                    id: item.id,
                    recipientName: item.recipientName,
                })),
            );
        } catch (error) {
            console.error('Error during API hit:', error);
        }
    };

    useEffect(() => {
        fetchPremiumSchedules();
        fetchPremiumTypeData();
        fetchRecipientData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleFormSubmit = async () => {
        try {
            await form.validateFields();

            const scheduleRows = form.getFieldValue('scheduleRows');
            const scheduleName = form.getFieldValue('scheduleName');

            const totalAllocation = scheduleRows.reduce(
                (sum: number, row: PremiumScheduleData) => sum + (parseFloat(row.allocation) || 0),
                0,
            );

            if (totalAllocation !== 100) {
                message.error('Allocation values must sum to 100%');
                return;
            }

            const recipientMapping = recipients?.reduce(
                (acc, recipient: any) => {
                    acc[recipient.recipientName] = recipient?.id;
                    return acc;
                },
                {} as Record<string, string>,
            );

            const validRecipientNames = Object.keys(recipientMapping);
            const isValidRecipients = scheduleRows.every((row: any) => validRecipientNames?.includes(row?.recipient));

            if (!isValidRecipients) {
                message.error('Each recipient must be selected from the dropdown options.');
                return;
            }

            const premiumSchedules = scheduleRows?.map((row: any) => ({
                premiumType: row?.premiumType,
                recipientId: recipientMapping[row?.recipient],
                allocationAmount: parseFloat(row?.allocation),
            }));

            const payload = {
                premiumScheduleName: scheduleName,
                premiumSchedules,
            };

            try {
                const response = await callApi(PREMIUM_SPLIT_SCHEDULE, 'POST', payload);
                if (response?.status) {
                    fetchPremiumSchedules();
                    toastSuccess('Premium Schedule created successfully.');
                    window.location.reload();
                } else {
                    toastError('Error creating Premium Schedule');
                }
            } catch (error) {
                console.error('Error during API hit:', error);
            }

            const newChildrenData = scheduleRows?.map((row: any, index: any) => ({
                key: `${Date.now()}-${index}`,
                premiumType: row?.premiumType,
                recipient: row?.recipient,
                allocation: row?.allocation,
            }));

            const newParentData: TableItem = {
                key: `${Date.now()}`,
                name: scheduleName,
                children: newChildrenData,
            };

            setScheduleListData((prevData) => [...prevData, newParentData]);

            form.resetFields();
            setAllocationValue(0);
            setIsSaveDisabled(true);
        } catch (error) {
            console.error('Error saving schedule:', error);
        }
    };

    const columns = [
        {
            title: 'Premium schedule name',
            dataIndex: 'name',
            key: 'name',
            width: 300,
            render: (text: string) => (
                <span style={{ wordBreak: 'break-word', whiteSpace: 'normal' }} className='schedule-name'>
                    {text}
                </span>
            ),
        },
        {
            title: 'Premium Type',
            dataIndex: 'premiumType',
            key: 'premiumType',
        },
        {
            title: 'Recipient',
            dataIndex: 'recipient',
            key: 'recipient',
            ellipsis: false,
            render: (text: string) => <span style={{ wordBreak: 'break-word', whiteSpace: 'normal' }}>{text}</span>,
        },
        {
            title: 'Allocation',
            dataIndex: 'allocation',
            key: 'allocation',
            width: 200,
        },
    ];

    const onPageChange = async (event: any) => {
        setPagination(event);
        fetchPremiumSchedules(event);
    };

    const deleteData = (name: string) => {
        if (!name) {
            return;
        }
        const data = form.getFieldValue('scheduleRows');
        const updatedData = data?.filter((item: any) => item?.recipient !== name);
        setSelectedRecipients((prev) => prev?.filter((item) => item !== name));
        form.setFieldValue('scheduleRows', updatedData);
    };

    const handleScheduleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const scheduleName = event.target.value.trim();

        setIsFieldsEnabled(!!scheduleName);
    };

    const handleExpand = (expanded: boolean, record: any) => {
        if (expanded) {
            const childKeys = record?.children ? record?.children?.map((child: any) => child.key) : [];
            const keys = [...expandedRowKeys, record?.key, ...childKeys];
            setExpandedRowKeys(keys);
        } else {
            const keys = expandedRowKeys.filter((key: any) => {
                if (key === record?.key) return false;
                return !(record?.children || []).some((child: any) => child?.key === key);
            });
            setExpandedRowKeys(keys);
        }
    };

    return (
        <div className='schedule-container'>
            <Form
                form={form}
                layout='vertical'
                className='premium-schedule-form'
                initialValues={{
                    scheduleRows: Array(4).fill({ premiumType: undefined, recipient: undefined, allocation: '' }),
                    total: '0%',
                }}
                onValuesChange={handleValuesChange}>
                <div className='form-header'>
                    <div className='name-section'>
                        <h2 style={{ fontWeight: 'bold', marginTop: '9px', fontSize: '20px' }}>
                            Premium Schedule Name*
                        </h2>
                        <Form.Item name='scheduleName' className='schedule-name-input'>
                            <Input
                                onChange={handleScheduleNameChange}
                                placeholder='Enter premium schedule name'
                                className='custom-input'
                                maxLength={255}
                            />
                        </Form.Item>
                    </div>
                    <Button
                        type='primary'
                        className='add-premium-btn'
                        icon={<span>+</span>}
                        onClick={handleAddPremiumType}
                        disabled={!isFieldsEnabled}>
                        Add premium type
                    </Button>
                </div>

                <hr />

                <div className='schedule-grid'>
                    <Form.List name='scheduleRows'>
                        {(fields, { remove }) => (
                            <Table
                                className='schedule-rows-table'
                                dataSource={fields?.map((field) => ({
                                    key: field.key,
                                    ...form.getFieldValue(['scheduleRows', field.name]),
                                }))}
                                pagination={false}
                                columns={[
                                    {
                                        title: 'Premium Type*',
                                        dataIndex: 'premiumType',
                                        key: 'premiumType',
                                        render: (_, record, index) => {
                                            const field = fields[index];
                                            return (
                                                <Form.Item {...field} name={[field.name, 'premiumType']}>
                                                    <AutoComplete
                                                        options={premiumTypes?.map((type) => ({ value: type }))}
                                                        placeholder='Enter premium type'
                                                        className='custom-select'
                                                        allowClear
                                                        disabled={!isFieldsEnabled}
                                                    />
                                                </Form.Item>
                                            );
                                        },
                                    },
                                    {
                                        title: 'Recipient*',
                                        dataIndex: 'recipient',
                                        key: 'recipient',
                                        render: (_, record, index) => {
                                            const field = fields[index];
                                            return (
                                                <Form.Item {...field} name={[field.name, 'recipient']}>
                                                    <AutoComplete
                                                        placeholder='Select recipient'
                                                        options={recipients?.map((recipient: any) => ({
                                                            value: recipient.recipientName,
                                                            label: recipient.recipientName,
                                                            disabled:
                                                                selectedRecipients.includes(recipient.recipientName) &&
                                                                !selectedRecipients.includes(
                                                                    form.getFieldValue([
                                                                        'scheduleRows',
                                                                        field.name,
                                                                        'recipient',
                                                                    ]),
                                                                ),
                                                        }))}
                                                        onChange={(value) => handleRecipientChange(value, field.name)}
                                                        filterOption={(inputValue, option) =>
                                                            option?.label
                                                                .toLowerCase()
                                                                .includes(inputValue.toLowerCase())
                                                        }
                                                        disabled={!isFieldsEnabled}
                                                    />
                                                </Form.Item>
                                            );
                                        },
                                    },
                                    {
                                        title: 'Allocation*',
                                        dataIndex: 'allocation',
                                        key: 'allocation',
                                        render: (_, record, index) => {
                                            const field = fields[index];
                                            return (
                                                <Form.Item
                                                    {...field}
                                                    name={[field.name, 'allocation']}
                                                    className='custom-allocation-input'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Allocation is required',
                                                        },
                                                        {
                                                            pattern: REGEX_PERCENTAGE,
                                                            message: ' ',
                                                        },
                                                    ]}>
                                                    <Input
                                                        placeholder='Enter percentage'
                                                        className='custom-input'
                                                        onChange={(e) => {
                                                            const value = e.target.value;
                                                            const formattedValue = REGEX_PERCENTAGE.test(value)
                                                                ? value
                                                                : value.slice(0, -1);

                                                            form.setFieldValue(
                                                                ['scheduleRows', field.name, 'allocation'],
                                                                formattedValue,
                                                            );
                                                        }}
                                                        value={form.getFieldValue([
                                                            'scheduleRows',
                                                            field.name,
                                                            'allocation',
                                                        ])}
                                                        disabled={!isFieldsEnabled}
                                                    />
                                                </Form.Item>
                                            );
                                        },
                                    },
                                    {
                                        title: '',
                                        key: 'actions',
                                        render: (_, record, index) => {
                                            const field = fields[index];
                                            return (
                                                <img
                                                    src={DeleteIcon}
                                                    alt='Delete Icon'
                                                    width={46}
                                                    height={46}
                                                    style={{
                                                        margin: 'auto',
                                                        cursor: isFieldsEnabled ? 'pointer' : 'not-allowed',
                                                        opacity: isFieldsEnabled ? 1 : 0.5,
                                                    }}
                                                    onClick={() => {
                                                        if (fields.length > 1) {
                                                            deleteData(
                                                                form.getFieldValue('scheduleRows')[index]?.recipient,
                                                            );
                                                            remove(field.name);
                                                        }
                                                    }}
                                                />
                                            );
                                        },
                                    },
                                ]}
                                footer={() => (
                                    <div className='total-row'>
                                        <div className='total-label'>TOTAL</div>
                                        <div className='total-value'>{allocationValue}%</div>
                                    </div>
                                )}
                            />
                        )}
                    </Form.List>
                </div>

                <div className='form-footer'>
                    <Button
                        type='primary'
                        className='save-btn'
                        onClick={handleFormSubmit}
                        disabled={isSaveDisabled}
                        style={{ opacity: isSaveDisabled ? 0.5 : 1 }}>
                        Save
                    </Button>
                </div>
            </Form>

            <hr />

            <div className='schedule-list-section'>
                <h2 style={{ fontSize: '20px', fontWeight: 700 }}>List of all Premium Schedules</h2>
                <Table
                    onChange={onPageChange}
                    columns={columns}
                    dataSource={scheduleListData}
                    pagination={{
                        ...pagination,
                        total: totalCount,
                        showSizeChanger: false,
                    }}
                    expandable={{
                        expandIcon: ({ expanded, onExpand, record }) =>
                            record.children?.length ? (
                                expanded ? (
                                    <UpOutlined
                                        style={{ marginRight: '15px', height: '10px' }}
                                        onClick={(e) => onExpand(record, e)}
                                    />
                                ) : (
                                    <DownOutlined
                                        style={{ marginRight: '15px' }}
                                        onClick={(e) => onExpand(record, e)}
                                    />
                                )
                            ) : null,
                        rowExpandable: (record) => !!record.children?.length,
                        onExpand: handleExpand,
                    }}
                    scroll={{
                        y: 500,
                    }}
                    expandedRowKeys={expandedRowKeys}
                    rowClassName={(record) => (expandedRowKeys.includes(record.key) ? 'expanded-row' : '')}
                    className='schedule-table'
                    onRow={(record) => ({
                        onClick: (event) => {
                            // Only expand if the row has children
                            if (record.children?.length) {
                                const isExpanded = expandedRowKeys.includes(record.key);

                                // Toggle row expansion
                                const newExpandedRowKeys = isExpanded
                                    ? expandedRowKeys.filter((key: any) => key !== record.key)
                                    : [...expandedRowKeys, record.key];

                                setExpandedRowKeys(newExpandedRowKeys);
                            }
                        },
                        style: {
                            cursor: record.children?.length ? 'pointer' : 'default',
                        },
                    })}
                />
            </div>
        </div>
    );
};

export default ScheduleSetup;
