import { errorMsg } from '../constants/messages';

const ClaimModuleListData = [
    {
        name: 'claimRequestId',
        label: 'Claim request id',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Claim request id', allowAddRecordClick: true },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'companyName',
        label: 'Company Name',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Company Name' },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'policyId',
        label: 'Policy Id',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Policy Id', allowAddRecordClick: true, openPdfModal: true },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'claimAmount',
        label: 'Claim Amount',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Claim Amount' },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'authorisedSigner',
        label: 'Authorised Signer',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Authorised Signer' },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'phone',
        label: 'Phone',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Phone' },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'address',
        label: 'Address',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Address' },
        isTableHeader: true,
        isAccordian: false,
    },
    {
        name: 'signerTitle',
        label: 'Signer Title',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Signer Title' },
        isTableHeader: true,
        isAccordian: false,
    },
];

export { ClaimModuleListData };
